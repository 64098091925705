import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { ModalBasic, ModalBasicBody, ModalBasicFooter } from "~/components/ModalBasic/ModalBasic";
import * as controllerPedidoVendas from "~/controller/Vendas/controllerPedidoVendas"
import '~/views/Vendas/Pedido/Modals/styles.css'
import { maskMoeda,removeMaskMoney } from "~/Util/mascaras";
import { alertar } from "~/Util/alertar";

import {
    Input,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    CardTitle,
    CardFooter, 
    NavItem,
    NavLink,
    Nav,  
} from "reactstrap";


const ModalFaturar = ({isOpen, toggleModalFaturar, pedidoPedidoFaturar, shouldClean}) => {

    const login = useSelector((state) => state.login);
    const notificacao = useSelector((state) => state.notificacao);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

/*     const [cardForma, setcardForma] = React.useState("PIX");

    const changeActiveCard = (e, cardName) => {
      e.preventDefault();
          setcardForma(cardName);
    };

 */    

    // Estado para controlar a cor de fundo
    const corFormaSelecionada = '#6e6e6e'; 
    const corFormaNormal = '#cecece';

    const [bgColor, setBgColor] = useState(corFormaNormal); // Cor inicial
    const [bgColorDinheiro, setBgColorDinheiro] = useState(corFormaNormal); // Cor inicial
    const [bgColorDebito, setBgColorDebito] = useState(corFormaNormal); // Cor inicial
    const [bgColorCredito, setBgColorCredito] = useState(corFormaNormal); // Cor inicial

    // Função para alternar a cor de fundo ao clicar e armazenar a forma de pagamento
    const toggleBackgroundColor = (e, cardName) => {
      setBgColor(prevColor => (prevColor === corFormaNormal ? corFormaSelecionada : corFormaNormal));
      setBgColorDinheiro(corFormaNormal);
      setBgColorDebito(corFormaNormal);
      setBgColorCredito(corFormaNormal);

      inicializaValores(cardName);

      pedidoPedidoFaturar.formaPagamento = '2';
      if (bgColor === corFormaSelecionada){
        pedidoPedidoFaturar.formaPagamento = '';
      }
      let idStore = 'PEDIDO'
      let type = 'PEDIDO_FATURAR'

      dispatch({ idStore, type, campo: 'formaPagamento', value: pedidoPedidoFaturar.formaPagamento })

    };

    // Função para alternar a cor de fundo ao clicar e armazenar a forma de pagamento
    const toggleBackgroundColorDinheiro = (e, cardName) => {
      setBgColor(corFormaNormal);
      setBgColorDinheiro(prevColor => (prevColor === corFormaNormal ? corFormaSelecionada : corFormaNormal));
      setBgColorDebito(corFormaNormal);
      setBgColorCredito(corFormaNormal);

      inicializaValores(cardName);
      document.getElementById("valorPagoDigitado").focus();
      
      pedidoPedidoFaturar.formaPagamento = '1';
      if (bgColorDinheiro === corFormaSelecionada){
        pedidoPedidoFaturar.formaPagamento = '';
      }
      let idStore = 'PEDIDO'
      let type = 'PEDIDO_FATURAR'

      dispatch({ idStore, type, campo: 'formaPagamento', value: pedidoPedidoFaturar.formaPagamento })

    };

    // Função para alternar a cor de fundo ao clicar e armazenar a forma de pagamento
    const toggleBackgroundColorDebito = (e, cardName) => {
      setBgColor(corFormaNormal);
      setBgColorDinheiro(corFormaNormal);
      setBgColorDebito(prevColor => (prevColor === corFormaNormal ? corFormaSelecionada : corFormaNormal));
      setBgColorCredito(corFormaNormal);

      inicializaValores(cardName);

      pedidoPedidoFaturar.formaPagamento = '3';
      if (bgColorDebito === corFormaSelecionada){
        pedidoPedidoFaturar.formaPagamento = '';
      }
      let idStore = 'PEDIDO'
      let type = 'PEDIDO_FATURAR'

      dispatch({ idStore, type, campo: 'formaPagamento', value: pedidoPedidoFaturar.formaPagamento })

    };

    // Função para alternar a cor de fundo ao clicar e armazenar a forma de pagamento
    const toggleBackgroundColorCredito = (e, cardName) => {
      setBgColor(corFormaNormal);
      setBgColorDinheiro(corFormaNormal);
      setBgColorDebito(corFormaNormal);
      setBgColorCredito(prevColor => (prevColor === corFormaNormal ? corFormaSelecionada : corFormaNormal));

      inicializaValores(cardName);

      pedidoPedidoFaturar.formaPagamento = '4';
      if (bgColorCredito === corFormaSelecionada){
        pedidoPedidoFaturar.formaPagamento = '';
      }
      let idStore = 'PEDIDO'
      let type = 'PEDIDO_FATURAR'

      dispatch({ idStore, type, campo: 'formaPagamento', value: pedidoPedidoFaturar.formaPagamento })

    };


    function inicializaValores(cardName){
      let idStore = 'PEDIDO'
      let type = 'PEDIDO_FATURAR'

      dispatch({ idStore, type, campo: 'valorDesconto', value: '0,00' })
      dispatch({ idStore, type, campo: 'valorTaxa', value: pedidoPedidoFaturar.valorTaxa })
      dispatch({ idStore, type, campo: 'valorParcial', value: pedidoPedidoFaturar.valorParcial })
      dispatch({ idStore, type, campo: 'valorEntrega', value: pedidoPedidoFaturar.valorEntrega })
      dispatch({ idStore, type, campo: 'valorSubTotal', value: pedidoPedidoFaturar.valorSubTotal })
      dispatch({ idStore, type, campo: 'valorRestante', value: '0,00' })
      dispatch({ idStore, type, campo: 'valorTroco', value: '0,00' })
      dispatch({ idStore, type, campo: 'valorTotal', value: pedidoPedidoFaturar.valorTotal })
      dispatch({ idStore, type, campo: 'valorPagoDigitado', value: pedidoPedidoFaturar.valorTotal })
    }

    function atualizaValores(){
      let idStore = 'PEDIDO'
      let type = 'PEDIDO_FATURAR'


      var valorSubTotal = 0;
      var valorTaxa = 0;
      
      var valorDesconto = 0;
      var valorParcial = 0;
      var valorTotal = 0;
      var valorRestante = 0;
      var valorTroco = 0;
      var valorPago = 0;
      var valorEntrega = 0;

      valorSubTotal = removeMaskMoney(pedidoPedidoFaturar.valorSubTotal);
      valorTaxa = removeMaskMoney(pedidoPedidoFaturar.valorTaxa);
      valorParcial = removeMaskMoney(pedidoPedidoFaturar.valorParcial);
      valorDesconto = removeMaskMoney(pedidoPedidoFaturar.valorDesconto);
      valorEntrega = removeMaskMoney(pedidoPedidoFaturar.valorEntrega);

      valorTotal = Number(valorSubTotal) + Number(valorTaxa) + Number(valorEntrega) - Number(valorDesconto) - Number(valorParcial);  
      valorTotal = valorTotal.toFixed(2);

      valorRestante = removeMaskMoney(pedidoPedidoFaturar.valorRestante);
      valorPago = removeMaskMoney(pedidoPedidoFaturar.valorPagoDigitado);

      //Forma Dinheiro
      if (pedidoPedidoFaturar.formaPagamento === '1'){
        if (valorPago !== ''){
          if (Number(valorPago) > Number(valorTotal)){
            valorTroco = Number(valorPago) - Number(valorTotal);    
            valorTroco = valorTroco.toFixed(2);

          }else if (Number(valorPago) < Number(valorTotal)){
            valorTroco = 0;
            valorRestante =  Number(valorTotal) - Number(valorPago);   
            valorRestante =  valorRestante.toFixed(2);
          }else if (Number(valorPago) === Number(valorTotal)){
            valorRestante = '0.00';
            valorTroco = '0.00';
          }
        }    
      }

      dispatch({ idStore, type, campo: 'valorDesconto', value: pedidoPedidoFaturar.valorDesconto })
      dispatch({ idStore, type, campo: 'valorAcrescimo', value: '0,00' })
      dispatch({ idStore, type, campo: 'valorTaxa', value: pedidoPedidoFaturar.valorTaxa })
      dispatch({ idStore, type, campo: 'valorParcial', value: pedidoPedidoFaturar.valorParcial })
      dispatch({ idStore, type, campo: 'valorEntrega', value: pedidoPedidoFaturar.valorEntrega })
      dispatch({ idStore, type, campo: 'valorSubTotal', value: pedidoPedidoFaturar.valorSubTotal })
      dispatch({ idStore, type, campo: 'valorRestante', value: maskMoeda(valorRestante) })
      dispatch({ idStore, type, campo: 'valorTroco', value: maskMoeda(valorTroco) })
      dispatch({ idStore, type, campo: 'valorTotal', value: maskMoeda(valorTotal) })
      dispatch({ idStore, type, campo: 'valorPagoDigitado', value: maskMoeda(valorPago) })
    }

    useEffect(() => {

      atualizaValores();

      //setpesquisa({ ...pesquisa, dataInicio: dataPesqInicio, dataFinal: dataPesqFinal, pedidoAberto: 'S' })

    }, [])
    
/*       useEffect(() => {
      if (pedidoPedidoItem !== null){
        setitemEditado({ ...itemEditado, idPedido: pedidoPedidoItem.idPedido,
                                         idEmpresa: pedidoPedidoItem.idEmpresa,
                                         idPedidoItem: pedidoPedidoItem.idPedidoItem,
                                         idProduto: pedidoPedidoItem.idProduto,
                                         quantidade: pedidoPedidoItem.quantidade,
                                         valorItem: pedidoPedidoItem.valorItem,
                                         desconto: pedidoPedidoItem.desconto,
                                         valorTotal: pedidoPedidoItem.valorTotal,
                                         observacaoItem: pedidoPedidoItem.observacaoItem                                           
         })
      }
    }, [])
 */ 
/*     useEffect(() => {
        if (shouldClean) {
          let idStore = 'PEDIDO'
          dispatch({ idStore, type: 'PEDIDOs', value: [] })  
          setpesquisa({ ...pesquisa, nomeCliente: '' })
        }
    }, [shouldClean]) */


    /*-----------------------------------------------------------------
    * ALTERAÇÃO DE CAMPOS
    *-----------------------------------------------------------------*/
    function handleOnChange(campo, value) {
      let idStore = 'PEDIDO'
      dispatch({ idStore, type: "PEDIDO_FATURAR", campo, value });
      // if (campo === 'valorDesconto'){
      //   var valorTotal = 0;
      //   var valorDesconto = 0;
      //   if (value !== ''){
      //     valorTotal = removeMaskMoney(pedidoPedidoFaturar.valorTotal);
      //     valorDesconto = removeMaskMoney(pedidoPedidoFaturar.valorDesconto);
      //     valorTotal = valorTotal - valorDesconto;    
      //   }  
      // }
    }


/*     function handleOnChange(value, campo) {
      switch (campo) {
        case "desconto":
          //setitemEditado({ ...itemEditado, desconto: value })                    
          break;
        case "observacaoItem":
          //setitemEditado({ ...itemEditado, observacaoItem: value })                    
          break;
        default:
          break;
      }   
    }
 */
    function handleVoltar(){
      let idStore = 'PEDIDO_ITEM'
      dispatch({ idStore, type: "CLEAN_PEDIDO_ITEM" });

      toggleModalFaturar();
    }

    async function handleGravar(){
      if (pedidoPedidoFaturar.formaPagamento === ''){
        alertar(notificacao, 'br', "SELECIONE a Forma de Pagamento.", 'info', 'icon-alert-circle-exc', 2);
      }else{
        setLoading(true);
        if (!loading) {
          await controllerPedidoVendas.FecharConta(notificacao, dispatch, pedidoPedidoFaturar, () => {
  
          });  
        }
        setLoading(false);
  
        let idStore = 'PEDIDO_ITEM'
        dispatch({ idStore, type: "CLEAN_PEDIDO_ITEM" });

        toggleModalFaturar();  
      }
    }

    function handleKeyPress(event, campo) {
        if (event.key === 'Enter') {
          if (campo === 'valorDesconto'){
            atualizaValores();
          }else if (campo === 'valorPagoDigitado'){
            atualizaValores();
          }
        }
    }

    return (
          <ModalBasic
            id="modalItem"
            isOpen={isOpen}
            toggle={() => toggleModalFaturar()}
            title={'Fechar Conta/Pedido'}
            size="lg" // sm, md, lg, xl = TAMANHO da Modal
            style={{backgroundColor: "silver"}}
          > 
            <ModalBasicBody>
                <Row md="12" className="padding-padrao-row">
                  <Col md="5" className="padding-padrao-col" >
                    <fieldset className="fieldset-resultados" style={{ height: '100%' }}>
                      <legend>Formas de Pagamento</legend>
                        <Row className="padding-padrao-row" style={{ marginTop: '20px'}}>
                          <Col>


                            <Card className="card-stats" 
                              style={{ backgroundColor: bgColor, cursor: 'pointer' }} // Define a cor dinamicamente
                              onClick={(e) =>
                                toggleBackgroundColor(e, "PIX")
                              }                            
                            >
                              <CardBody>
                                <Row>
                                  <Col xs="3">
                                  
                                    {/* <div className="info-icon text-center icon-success">
                                      <i className="tim-icons icon-single-02" />
                                    </div> */}
                                    <div className="photo" style={{ width: '50px', height: '50px' }}>
                                      <img
                                        alt="..."
                                        src={require("~/assets/img/imagens/pix.png")}
                                      />
                                    </div>
                                  </Col>
                                  <Col xs="9">
                                    <div className="numbers">
                                      <p className="card-category">Pagar com</p>
                                      <CardTitle tag="h4">PIX</CardTitle>
                                    </div>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>                                  

                          </Col>
                        </Row>

                        <Row className="padding-padrao-row" style={{ marginTop: '-25px' }} >
                          <Col>
                            <Card className="card-stats"
                              style={{ backgroundColor: bgColorDinheiro, cursor: 'pointer' }} // Define a cor dinamicamente
                              onClick={(e) =>
                                toggleBackgroundColorDinheiro(e, "DINHEIRO")
                              }                            

                            >
                              <CardBody>
                                <Row>
                                  <Col xs="3">
                                    {/* <div className="info-icon text-center icon-success">
                                      <i className="tim-icons icon-single-02" />
                                    </div> */}
                                    <div className="photo" style={{ width: '50px', height: '50px' }}>
                                      <img
                                        alt="..."
                                        src={require("~/assets/img/imagens/dinheiro.png")}
                                      />
                                    </div>
                                  </Col>
                                  <Col xs="9">
                                    <div className="numbers">
                                      <p className="card-category">Pagar com</p>
                                      <CardTitle tag="h4">DINHEIRO</CardTitle>
                                    </div>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>

                        <Row className="padding-padrao-row" style={{ marginTop: '-25px' }}>
                          <Col>
                            <Card className="card-stats"
                              style={{ backgroundColor: bgColorDebito, cursor: 'pointer' }} // Define a cor dinamicamente
                              onClick={(e) =>
                                toggleBackgroundColorDebito(e, "DEBITO")
                              }                                                        
                            >
                              <CardBody>
                                <Row>
                                  <Col xs="3">
                                    {/* <div className="info-icon text-center icon-success">
                                      <i className="tim-icons icon-single-02" />
                                    </div> */}
                                    <div className="photo" style={{ width: '50px', height: '50px' }}>
                                      <img
                                        alt="..."
                                        src={require("~/assets/img/imagens/cartao_debito_cinza.png")}
                                      />
                                    </div>
                                  </Col>
                                  <Col xs="9">
                                    <div className="numbers">
                                      <p className="card-category">Pagar com</p>
                                      <CardTitle tag="h4">CARTÃO DÉBITO</CardTitle>
                                    </div>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>

                        <Row className="padding-padrao-row" style={{ marginTop: '-25px' }}>
                          <Col>
                            <Card className="card-stats"
                              style={{ backgroundColor: bgColorCredito, cursor: 'pointer' }} // Define a cor dinamicamente
                              onClick={(e) =>
                                toggleBackgroundColorCredito(e, "CREDITO")
                              }                                                        
                            >
                              <CardBody>
                                <Row>
                                  <Col xs="3">
                                    {/* <div className="info-icon text-center icon-success">
                                      <i className="tim-icons icon-single-02" />
                                    </div> */}
                                    <div className="photo" style={{ width: '50px', height: '50px' }}>
                                      <img
                                        alt="..."
                                        src={require("~/assets/img/imagens/cartao_credito.png")}
                                      />
                                    </div>
                                  </Col>
                                  <Col xs="9">
                                    <div className="numbers">
                                      <p className="card-category">Pagar com</p>
                                      <CardTitle tag="h4">CARTÃO CRÉDITO</CardTitle>
                                    </div>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>

                    </fieldset>    
                  </Col>

                  <Col className="padding-padrao-col">
                    <fieldset className="fieldset-resultados" style={{ height: '100%' }}>
                      <legend>Resumo do Pedido</legend>
                        <Row className="padding-padrao-row">
                          <Col className="padding-padrao-col" lg="5" md="1" style={{ marginTop: '0px' }}>
                            <Input
                              id="labelSubTotal"
                              type="text"
                              autoComplete="off"
                              disabled
                              style={{ fontSize: '18px', textAlign: 'left', backgroundColor: '#969696' }}
                              value="SubTotal R$"
                            />
                          </Col>
     
                          <Col className="padding-padrao-col" lg="7" md="2" style={{ marginTop: '0px' }}>
                            <Input
                              disabled
                              tabIndex={6}
                              style={{textAlign: 'right', fontSize: '22px', color: '#fff', backgroundColor: '#646464' }}
                              borderTopRightRadius="0"
                              id="valorSubTotal"
                              type="text"
                              autoComplete="off"
                              value={pedidoPedidoFaturar.valorSubTotal}
/*                               onChange={(e) =>
                                handleOnChangeProduto(maskMoeda(e.target.value), e.target.id)
                              } */
                            />
                          </Col>          
                        </Row>

                        <Row className="padding-padrao-row">
                          <Col className="padding-padrao-col" lg="5" md="1" style={{ marginTop: '0px' }}>
                            <Input
                              id="labelDesconto"
                              type="text"
                              autoComplete="off"
                              disabled
                              style={{ fontSize: '18px', textAlign: 'left', backgroundColor: '#969696' }}
                              value="Desconto R$"
                            />
                          </Col>
     
                          <Col className="padding-padrao-col" lg="7" md="2" style={{ marginTop: '0px' }}>
                            <Input
                              tabIndex={6}
                              style={{textAlign: 'right', textTransform:"uppercase" , fontSize: '22px', color: 'black', backgroundColor: '#d8d334' }}
                              borderTopRightRadius="0"
                              id="valorDesconto"
                              type="text"
                              autoComplete="off"
                              value={pedidoPedidoFaturar.valorDesconto}
                              onChange={(e) =>
                                handleOnChange(e.target.id, maskMoeda(e.target.value))
                              }
                              onFocus={e => e.target.select()}
                              onKeyPress={(e) => {
                                if (e.target.value !== ''){
                                  handleKeyPress(e, e.target.id);
                                  //if (e.key === 'Enter') e.target.select()  
                                }
                              }}
                              maxLength="15"
                            />
                          </Col>          
                        </Row>    

                        <Row className="padding-padrao-row">
                          <Col className="padding-padrao-col" lg="5" md="1" style={{ marginTop: '0px' }}>
                            <Input
                              id="labelTaxa"
                              type="text"
                              autoComplete="off"
                              disabled
                              style={{ fontSize: '18px', textAlign: 'left', backgroundColor: '#969696' }}
                              value="Tx. Serviço R$"
                            />
                          </Col>
     
                          <Col className="padding-padrao-col" lg="7" md="2" style={{ marginTop: '0px' }}>
                            <Input
                              disabled
                              tabIndex={6}
                              style={{textAlign: 'right', fontSize: '22px', color: '#fff', backgroundColor: '#646464' }}
                              borderTopRightRadius="0"
                              id="valorTaxa"
                              type="text"
                              autoComplete="off"
                              value={pedidoPedidoFaturar.valorTaxa}
/*                               onChange={(e) =>
                                handleOnChangeProduto(maskMoeda(e.target.value), e.target.id)
                              } */
                            />
                          </Col>          
                        </Row>

                        <Row className="padding-padrao-row">
                          <Col className="padding-padrao-col" lg="5" md="1" style={{ marginTop: '0px' }}>
                            <Input
                              id="labelParcial"
                              type="text"
                              autoComplete="off"
                              disabled
                              style={{ fontSize: '18px', textAlign: 'left', backgroundColor: '#969696' }}
                              value="Parcial R$"
                            />
                          </Col>
     
                          <Col className="padding-padrao-col" lg="7" md="2" style={{ marginTop: '0px' }}>
                            <Input
                              disabled
                              tabIndex={6}
                              style={{textAlign: 'right', fontSize: '22px', color: '#fff', backgroundColor: '#646464' }}
                              borderTopRightRadius="0"
                              id="valorParcial"
                              type="text"
                              autoComplete="off"
                              value={pedidoPedidoFaturar.valorParcial}
/*                               onChange={(e) =>
                                handleOnChangeProduto(maskMoeda(e.target.value), e.target.id)
                              } */
                            />
                          </Col>          
                        </Row>

                        <Row className="padding-padrao-row">
                          <Col className="padding-padrao-col" lg="5" md="1" style={{ marginTop: '0px' }}>
                            <Input
                              id="labelRestante"
                              type="text"
                              autoComplete="off"
                              disabled
                              style={{ fontSize: '18px', textAlign: 'left', backgroundColor: '#969696' }}
                              value="Restante R$"
                            />
                          </Col>
     
                          <Col className="padding-padrao-col" lg="7" md="2" style={{ marginTop: '0px' }}>
                            <Input
                              disabled
                              tabIndex={6}
                              style={{textAlign: 'right', fontSize: '22px', color: '#fff', backgroundColor: '#646464' }}
                              borderTopRightRadius="0"
                              id="valorRestante"
                              type="text"
                              autoComplete="off"
                              value={pedidoPedidoFaturar.valorRestante}
/*                               onChange={(e) =>
                                handleOnChangeProduto(maskMoeda(e.target.value), e.target.id)
                              } */
                            />
                          </Col>          
                        </Row>

                        <Row className="padding-padrao-row">
                          <Col className="padding-padrao-col" lg="5" md="1" style={{ marginTop: '0px' }}>
                            <Input
                              id="labelTroco"
                              type="text"
                              autoComplete="off"
                              disabled
                              style={{ fontSize: '18px', textAlign: 'left', backgroundColor: '#969696' }}
                              value="Troco R$"
                            />
                          </Col>
     
                          <Col className="padding-padrao-col" lg="7" md="2" style={{ marginTop: '0px' }}>
                            <Input
                              disabled
                              tabIndex={6}
                              style={{textAlign: 'right', fontSize: '22px', color: '#fff', backgroundColor: '#646464' }}
                              borderTopRightRadius="0"
                              id="valorTroco"
                              type="text"
                              autoComplete="off"
                              value={pedidoPedidoFaturar.valorTroco}
/*                               onChange={(e) =>
                                handleOnChangeProduto(maskMoeda(e.target.value), e.target.id)
                              } */
                            />
                          </Col>          
                        </Row>

                        <Row className="padding-padrao-row" style={{ alignItems: 'end', marginTop: '15px' }}>
                          <Col className="padding-padrao-col" lg="5" md="1">
                            <Input
                              id="labelTotal"
                              type="text"
                              autoComplete="off"
                              disabled
                              style={{ fontSize: '18px', textAlign: 'left', backgroundColor: '#969696' }}
                              value="Total R$"
                            />
                          </Col>
     
                          <Col className="padding-padrao-col" lg="7" md="2" style={{ marginTop: '0px' }}>
                            <Input
                              disabled
                              tabIndex={6}
                              style={{textAlign: 'right', fontSize: '24px', fontWeight: 'bold', color: '#fff', backgroundColor: '#646464' }}
                              borderTopRightRadius="0"
                              id="valorTotal"
                              type="text"
                              autoComplete="off"
                              value={pedidoPedidoFaturar.valorTotal}
                            />
                          </Col>          
                        </Row>

                        <Row className="padding-padrao-row" style={{ alignItems: 'end', marginTop: '0px' }}>
                          <Col className="padding-padrao-col" lg="5" md="1">
                            <Input
                              id="labelValorPago"
                              type="text"
                              autoComplete="off"
                              disabled
                              style={{ fontSize: '18px', textAlign: 'left', backgroundColor: '#969696' }}
                              value="Valor Pago R$"
                            />
                          </Col>
     
                          <Col className="padding-padrao-col" lg="7" md="2" style={{ marginTop: '0px' }}>
                            <Input
                              tabIndex={6}
                              style={{textAlign: 'right', fontSize: '22px', fontWeight: 'bold', color: '#fff', backgroundColor: '#d8d334' }}
                              borderTopRightRadius="0"
                              id="valorPagoDigitado"
                              type="text"
                              autoComplete="off"
                              value={pedidoPedidoFaturar.valorPagoDigitado}
                              onChange={(e) =>
                                handleOnChange(e.target.id, maskMoeda(e.target.value))
                              }
                              onFocus={e => e.target.select()}
                              onKeyPress={(e) => {
                                if (e.target.value !== ''){
                                  handleKeyPress(e, e.target.id);
                                  //if (e.key === 'Enter') e.target.select()  
                                }
                              }}
                            />
                          </Col>          
                        </Row>

                    </fieldset>    
                  </Col>
                </Row>


            </ModalBasicBody>

            <ModalBasicFooter>
                <Row className="justify-content-center">
                    <Col lg md sm>
                        <Col className="text-center">
                            <Button
                                className='btn btn-green'
                                //color="info"
                                type="button"
                                onClick={handleVoltar}
                                style={{ width: '130px' }}
                            >Voltar
                            </Button>
                            <Button
                                color="success"
                                type="button"
                                onClick={() => handleGravar()}
                                style={{ width: '130px' }}
                            >Fechar Conta
                            </Button>
                        </Col>
                    </Col>
                </Row>
            </ModalBasicFooter>
          
        </ModalBasic>
      
    )
}

export default ModalFaturar
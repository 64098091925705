import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
import global from "~/Util/global";
import { useSelector} from "react-redux";


import * as controllerDashboard from "~/controller/controllerDashboard";

import { useState, useEffect } from "react";
//import ReactTable from "~/components/ReactTable/ReactTable.js";
import ReactTable from "react-table";
//import ReactTable from "react-table-6";
// reactstrap components

import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Progress,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// core components
import {
  chartExample1,
  chartExample2,
  chartExample3,
  chartExample4,
} from "~/variables/charts.jsx";

var empresaNome = localStorage.getItem('EMPRESA_NOME');
var qtdProdutosVendidosAno = localStorage.getItem('QTD_VENDIDOS_ANO');
var qtdPordutosVendidosMes = localStorage.getItem('QTD_VENDIDOS_MES');
var qtdBalcao = localStorage.getItem('QTD_BALCAO');
var qtdEntrega = localStorage.getItem('QTD_ENTREGA');
var qtdVendasDia = localStorage.getItem('QTD_VENDAS_DIA');
var qtdVendasDeliveryDia = localStorage.getItem('QTD_VENDAS_DELIVERY_DIA');
var qtdVendasUltimasHoras = localStorage.getItem('QTD_VENDAS_ULTIMAS_HORAS');
var qtdClientes = localStorage.getItem('QTD_CLIENTES');

var mostrarDashboard = '';

//Trocar para Ultimas Vendas
var ultimasIntegracoes = localStorage.getItem('ULTIMAS_INTEGRACOES');
var objUltimasIntegracoes = JSON.parse(ultimasIntegracoes);
var ultimosErros = localStorage.getItem('ULTIMOS_ERROS');
var objUltimosErros = JSON.parse(ultimosErros);
var logoEmpresa = '';

// class Dashboard extends React.Component {
//   constructor(props) {
//     super(props);

const Dashboard = () => {
  //const login = useSelector((state) => state.login);
  const acesso = useSelector((state) => state.ACESSO);  
  const [bigChartData, setbigChartData] = React.useState("data1");
  const setBgChartData = (name) => {
    setbigChartData(name);
  };

    empresaNome = localStorage.getItem('EMPRESA_NOME');    
    qtdProdutosVendidosAno = localStorage.getItem('QTD_VENDIDOS_ANO');
    qtdPordutosVendidosMes = localStorage.getItem('QTD_VENDIDOS_MES');
    qtdBalcao = localStorage.getItem('QTD_BALCAO');
    qtdEntrega = localStorage.getItem('QTD_ENTREGA');
    qtdVendasDia = localStorage.getItem('QTD_VENDAS_DIA');
    qtdVendasDeliveryDia = localStorage.getItem('QTD_VENDAS_DELIVERY_DIA');
    qtdVendasUltimasHoras = localStorage.getItem('QTD_VENDAS_ULTIMAS_HORAS');
    qtdClientes = localStorage.getItem('QTD_CLIENTES');
  
    if (mostrarDashboard === ''){
      mostrarDashboard = acesso.ACESSO.mostrarDashboard;
    }
      
    //ultimasIntegracoes = localStorage.getItem('ULTIMAS_INTEGRACOES');
    objUltimasIntegracoes = JSON.parse(ultimasIntegracoes);
    if (objUltimasIntegracoes != null){ 
      if (objUltimasIntegracoes.idEmpresa === 1) {
        logoEmpresa = global.urlImagem + "Lar.jpg" 
      }else if (objUltimasIntegracoes.idEmpresa === 2) {
        logoEmpresa = require("~/assets/img/SemImagem.jpg");
      }else if (objUltimasIntegracoes.idEmpresa === 3) {
        logoEmpresa = require("~/assets/img/SemImagem.jpg");
      }else if (objUltimasIntegracoes.idEmpresa === 4) {
        logoEmpresa = require("~/assets/img/Frimesa.jpg");
      }else if (objUltimasIntegracoes.idEmpresa === 5) {
        logoEmpresa = require("~/assets/img/Frisia.jpg");
      }else if (objUltimasIntegracoes.idEmpresa === 6) {
        logoEmpresa = require("~/assets/img/SemImagem.jpg");
      }else if (objUltimasIntegracoes.idEmpresa === 7) {
        logoEmpresa = require("~/assets/img/SemImagem.jpg");
      }else if (objUltimasIntegracoes.idEmpresa === 8) {
        logoEmpresa = require("~/assets/img/SemImagem.jpg");
      }else if (objUltimasIntegracoes.idEmpresa === 9) {
        logoEmpresa = require("~/assets/img/SemImagem.jpg");
      }
    }

    ultimosErros = localStorage.getItem('ULTIMOS_ERROS');
    objUltimosErros = JSON.parse(ultimosErros);    
        
    if (qtdVendasDia === "" || qtdVendasDia === null){
        qtdVendasDia = 0;
    }  

    if (qtdVendasDeliveryDia === "" || qtdVendasDeliveryDia === null){
      qtdVendasDeliveryDia = 0;
    }  


    if (qtdProdutosVendidosAno === "" || qtdProdutosVendidosAno === null){
      qtdProdutosVendidosAno = 0;
    }  

    if (qtdVendasUltimasHoras === "" || qtdVendasUltimasHoras === null){
      qtdVendasUltimasHoras = 0;
    }  

    if (qtdClientes === "" || qtdClientes === null){
      qtdClientes = 0;
    }  

    if (qtdBalcao === "" || qtdBalcao === null){
      qtdBalcao = 0;
    }  

    if (qtdEntrega === "" || qtdEntrega === null){
      qtdEntrega = 0;
    }  

    // this.state = {
    //   bigChartData: "data1",
    // };
    
  //}

  
  
  // setBgChartData = (name) => {
  //   this.setState({
  //     bigChartData: name,
  //   });
  // };





/*     data = this.setState(
      dataTable.map((prop, key) => {
        return {
          id: key,
          name: prop[0],
          position: prop[1],
          office: prop[2],
          age: prop[3],
        };
      })
    ); */

  //render() {

    return (
      <>
        <div className="content">
          {mostrarDashboard === 'S' ?
          <Row>  
            <Col xs="12">
              <Card className="card-chart">
                <CardHeader>
                  <Row>
                    <Col className="text-left" sm="6">
                      <h5 className="card-category">Vendas Finalizadas</h5>
                      <CardTitle tag="h4">{empresaNome}</CardTitle>
                    </Col>
                    <Col sm="6">
                      <ButtonGroup
                        className="btn-group-toggle float-right"
                        data-toggle="buttons"
                      >
                        <Button
                          color="info"
                          id="0"
                          size="sm"
                          tag="label"
                          className={classNames("btn-simple", {
                            //active: this.state.bigChartData === "data1",
                            active: bigChartData === "data1",
                          })}
                          //onClick={() => this.setBgChartData("data1")}
                          onClick={() => setBgChartData("data1")}
                        >
                          <input defaultChecked name="options" type="radio" />
                          <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                            Mensal
                          </span>
                          <span className="d-block d-sm-none">
                            <i className="tim-icons icon-single-02" />
                          </span>
                        </Button>
                        <Button
                          color="info"
                          id="1"
                          size="sm"
                          tag="label"
                          className={classNames("btn-simple", {
                            //active: this.state.bigChartData === "data2",
                            active: bigChartData === "data2",
                          })}
                          //onClick={() => this.setBgChartData("data2")}
                          onClick={() => setBgChartData("data2")}
                        >
                          <input name="options" type="radio" />
                          <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                            Semanal
                          </span>
                          <span className="d-block d-sm-none">
                            <i className="tim-icons icon-gift-2" />
                          </span>
                        </Button>
                        <Button
                          color="info"
                          id="2"
                          size="sm"
                          tag="label"
                          className={classNames("btn-simple", {
                            //active: this.state.bigChartData === "data3",
                            active: bigChartData === "data3",
                          })}
                          //onClick={() => this.setBgChartData("data3")}
                          onClick={() => setBgChartData("data3")}
                        >
                          <input name="options" type="radio" />
                          <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                            Diário
                          </span>
                          <span className="d-block d-sm-none">
                            <i className="tim-icons icon-tap-02" />
                          </span>
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart-area">
                    <Line
                      //data={chartExample1[this.state.bigChartData]}
                      data={chartExample1[bigChartData]}
                      options={chartExample1.options}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>


            
{/*             <Col lg="3" md="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col xs="5">
                      <div className="info-icon text-center icon-info">
                        <i className="tim-icons icon-cart" />
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Vendas do Dia</p>
                        <CardTitle tag="h3">{qtdVendasDia}</CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="tim-icons icon-refresh-01" /> Vendas no Estabelicimento
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col xs="5">
                      <div className="info-icon text-center icon-primary">
                        <i className="tim-icons icon-delivery-fast" />
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Delivery do Dia</p>
                        <CardTitle tag="h3">{qtdVendasDeliveryDia}</CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="tim-icons icon-sound-wave" /> Vendas Delivery
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col xs="5">
                      <div className="info-icon text-center icon-danger">
                        <i className="tim-icons icon-basket-simple" />
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Últimas Horas</p>
                        <CardTitle tag="h3">{qtdVendasUltimasHoras}</CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="tim-icons icon-watch-time" /> Vendas Nas últimas horas
                  </div>
                </CardFooter>
              </Card>
            </Col>            
            <Col lg="3" md="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col xs="5">
                      <div className="info-icon text-center icon-success">
                        <i className="tim-icons icon-single-02" />
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Clientes</p>
                        <CardTitle tag="h3">{qtdClientes}</CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="tim-icons icon-trophy" /> Clientes Cadastrados
                  </div>
                </CardFooter>
              </Card>
            </Col>
 */}



            <Col lg="4">
              <Card className="card-chart">
                <CardHeader>
                  <h5 className="card-category">Mais Vendidos no Ano Atual</h5>
                  <CardTitle tag="h3">
                    <i className="tim-icons icon-badge text-info" />{" "}
                    {qtdProdutosVendidosAno}
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <div className="chart-area">
                    <Line
                      data={chartExample2.data}
                      options={chartExample2.options}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4">
              <Card className="card-chart">
                <CardHeader>
                  <h5 className="card-category">Mais Vendidos no Mês Atual</h5>
                  <CardTitle tag="h3">
                    <i className="tim-icons icon-delivery-fast text-primary" />{" "}
                    {qtdPordutosVendidosMes}
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <div className="chart-area">
                     <Bar
                      data={chartExample3.data}
                      options={chartExample3.options}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4">
              <Card className="card-chart">
                <CardHeader>
                  <h5 className="card-category">Vendas Balcão x Delivery</h5>
                  <CardTitle tag="h3">
                    <i className="tim-icons icon-send text-success" /> {qtdBalcao}
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <div className="chart-area">
                    <Line
                      data={chartExample4.data}
                      options={chartExample4.options}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          : <></>
          }             

          {/* *************** FIM dos GRAFICOS *************** */}

          <Row>
{/*             <Col lg="5">
              <Card className="card-tasks">

                <CardHeader>
                  <h5 className="title d-inline">Últimas Vendas Delivery</h5>
                  <p className="card-category d-inline">Últimos horas</p>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-center">#</th>
                        <th>Data</th>
                        <th>Ocorrência</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-center">                          
                          {objUltimosErros === null ? "": objUltimosErros.statusErro[0] === "ERRO" ? 
                            <i className="tim-icons icon-settings text-danger" />: 
                            objUltimosErros.statusErro[0] === "ALERTA" ? 
                            <i className="tim-icons icon-alert-circle-exc text-warning" />: 
                            <i className="tim-icons icon-check-2 text-success" />}
                        </td>
                        <td>{objUltimosErros != null ? objUltimosErros.dataErro[0]: ""}</td>
                        <td id="descErro00">{objUltimosErros != null ? objUltimosErros.descricaoErro[0]: ""}</td>
                        <UncontrolledTooltip
                                delay={0}
                                target="descErro00"
                              >
                                {objUltimosErros != null ? objUltimosErros.toolTipErro[0]: ""}
                        </UncontrolledTooltip>
                      </tr>
                      <tr>
                        <td className="text-center">
                          {objUltimosErros === null ? "": objUltimosErros.statusErro[1] === "ERRO" ? 
                            <i className="tim-icons icon-settings text-danger" />: 
                            objUltimosErros.statusErro[1] === "ALERTA" ? 
                            <i className="tim-icons icon-alert-circle-exc text-warning" />: 
                            <i className="tim-icons icon-check-2 text-success" />}
                        </td>
                        <td>{objUltimosErros != null ? objUltimosErros.dataErro[1]: ""}</td>
                        <td id="descErro01">{objUltimosErros != null ? objUltimosErros.descricaoErro[1]: ""}</td>
                        <UncontrolledTooltip
                                delay={0}
                                target="descErro01"
                              >
                                {objUltimosErros != null ? objUltimosErros.toolTipErro[1]: ""}
                        </UncontrolledTooltip>                        
                      </tr>
                      <tr>
                        <td className="text-center">
                          {objUltimosErros === null ? "": objUltimosErros.statusErro[2] === "ERRO" ? 
                            <i className="tim-icons icon-settings text-danger" />: 
                            objUltimosErros.statusErro[2] === "ALERTA" ? 
                            <i className="tim-icons icon-alert-circle-exc text-warning" />: 
                            <i className="tim-icons icon-check-2 text-success" />}
                        </td>
                        <td>{objUltimosErros != null ? objUltimosErros.dataErro[2]: ""}</td>
                        <td id="descErro02">{objUltimosErros != null ? objUltimosErros.descricaoErro[2]: ""}</td>
                        <UncontrolledTooltip
                                delay={0}
                                target="descErro02"
                              >
                                {objUltimosErros != null ? objUltimosErros.toolTipErro[2]: ""}
                        </UncontrolledTooltip>
                      </tr>
                      <tr>
                        <td className="text-center">
                          {objUltimosErros === null ? "": objUltimosErros.statusErro[3] === "ERRO" ? 
                            <i className="tim-icons icon-settings text-danger" />: 
                            objUltimosErros.statusErro[3] === "ALERTA" ? 
                            <i className="tim-icons icon-alert-circle-exc text-warning" />: 
                            <i className="tim-icons icon-check-2 text-success" />}
                        </td>
                        <td>{objUltimosErros != null ? objUltimosErros.dataErro[3]: ""}</td>
                        <td id="descErro03">{objUltimosErros != null ? objUltimosErros.descricaoErro[3]: ""}</td>
                        <UncontrolledTooltip
                                delay={0}
                                target="descErro03"
                              >
                                {objUltimosErros != null ? objUltimosErros.toolTipErro[3]: ""}
                        </UncontrolledTooltip>
                      </tr>
                      <tr>
                        <td className="text-center">
                          {objUltimosErros === null ? "": objUltimosErros.statusErro[4] === "ERRO" ? 
                            <i className="tim-icons icon-settings text-danger" />: 
                            objUltimosErros.statusErro[4] === "ALERTA" ? 
                            <i className="tim-icons icon-alert-circle-exc text-warning" />: 
                            <i className="tim-icons icon-check-2 text-success" />}
                        </td>
                        <td>{objUltimosErros != null ? objUltimosErros.dataErro[4]: ""}</td>
                        <td id="descErro04">{objUltimosErros != null ? objUltimosErros.descricaoErro[4]: ""}</td>
                        <UncontrolledTooltip
                                delay={0}
                                target="descErro04"
                              >
                                {objUltimosErros != null ? objUltimosErros.toolTipErro[4]: ""}
                        </UncontrolledTooltip>
                      </tr>
                      <tr>
                        <td className="text-center">
                          {objUltimosErros === null ? "": objUltimosErros.statusErro[5] === "ERRO" ? 
                            <i className="tim-icons icon-settings text-danger" />: 
                            objUltimosErros.statusErro[5] === "ALERTA" ? 
                            <i className="tim-icons icon-alert-circle-exc text-warning" />: 
                            <i className="tim-icons icon-check-2 text-success" />}
                        </td>
                        <td>{objUltimosErros != null ? objUltimosErros.dataErro[5]: ""}</td>
                        <td id="descErro05">{objUltimosErros != null ? objUltimosErros.descricaoErro[5]: ""}</td>
                        <UncontrolledTooltip
                                delay={0}
                                target="descErro05"
                              >
                                {objUltimosErros != null ? objUltimosErros.toolTipErro[5]: ""}
                        </UncontrolledTooltip>
                      </tr>
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col> */}

            
{/*             <Col lg="7">
              <Card>
                <CardHeader>
                  <CardTitle tag="h5">Últimas Vendas Balcão</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-center">#</th>
                        <th>Empresa</th>
                        <th>Data Inicial</th>
                        <th>Data Final</th>
                        <th className="text-center">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-center">
                          <div className="photo">
                            <img
                              alt="..."
                              src={logoEmpresa != '' ? logoEmpresa: require("~/assets/img/SemImagem.jpg")}
                            />
                          </div>
                        </td>
                        <td>{empresaNome}</td>
                        <td>{objUltimasIntegracoes != null ? objUltimasIntegracoes.dataInicio[0]: "#"}</td>
                        <td>{objUltimasIntegracoes != null ? objUltimasIntegracoes.dataFinal[0]: "#"}</td>
                        <td className="text-center">
                          {objUltimasIntegracoes != null ? 
                            objUltimasIntegracoes.statusIntegracao[0] === "OK" ? 
                            
                             <Button
                              className="btn-link btn-icon"
                              color="success"
                              id="tooltip618296632"
                              size="sm"
                              title=""
                              type="button"
                            >
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip618296632"
                              >
                                Sucesso
                              </UncontrolledTooltip>                              
                              <i className="tim-icons icon-check-2" />
                            </Button>
                            : 
                            <Button
                              className="btn-link btn-icon"
                              color="danger"
                              id="tooltip707467505"
                              size="sm"
                              title=""
                              type="button"
                            >
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip707467505"
                              >
                                Possível Erro
                              </UncontrolledTooltip>                              
                              <i className="tim-icons icon-simple-remove" />
                            </Button>
                          : "#"}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">
                          <div className="photo">
                            <img
                              alt="..."
                              src={logoEmpresa != '' ? logoEmpresa: require("~/assets/img/SemImagem.jpg")}
                            />
                          </div>
                        </td>
                        <td>{empresaNome}</td>
                        <td>{objUltimasIntegracoes != null ? objUltimasIntegracoes.dataInicio[1]: "#"}</td>
                        <td>{objUltimasIntegracoes != null ? objUltimasIntegracoes.dataFinal[1]: "#"}</td>
                        <td className="text-center">
                          {objUltimasIntegracoes != null ? 
                            objUltimasIntegracoes.statusIntegracao[1] === "OK" ? 
                            
                             <Button
                              className="btn-link btn-icon"
                              color="success"
                              id="tooltip216846074"
                              size="sm"
                              title=""
                              type="button"
                            >
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip216846074"
                              >
                                Sucesso
                              </UncontrolledTooltip>                              
                              <i className="tim-icons icon-check-2" />
                            </Button>
                            : 
                            <Button
                              className="btn-link btn-icon"
                              color="danger"
                              id="tooltip391990405"
                              size="sm"
                              title=""
                              type="button"
                            >
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip391990405"
                              >
                                Possível Erro
                              </UncontrolledTooltip>                              
                              <i className="tim-icons icon-simple-remove" />
                            </Button>
                          : "#"}

                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">
                          <div className="photo">
                            <img
                              alt="..."
                              src={logoEmpresa != '' ? logoEmpresa: require("~/assets/img/SemImagem.jpg")}
                            />
                          </div>
                        </td>
                        <td>{empresaNome}</td>
                        <td>{objUltimasIntegracoes != null ? objUltimasIntegracoes.dataInicio[2]: "#"}</td>
                        <td>{objUltimasIntegracoes != null ? objUltimasIntegracoes.dataFinal[2]: "#"}</td>
                        <td className="text-center">
                          {objUltimasIntegracoes != null ? 
                            objUltimasIntegracoes.statusIntegracao[2] === "OK" ? 
                            
                             <Button
                              className="btn-link btn-icon"
                              color="success"
                              id="tooltip191500186"
                              size="sm"
                              title=""
                              type="button"
                            >
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip191500186"
                              >
                                Sucesso
                              </UncontrolledTooltip>                              
                              <i className="tim-icons icon-check-2" />
                            </Button>
                            : 
                            <Button
                              className="btn-link btn-icon"
                              color="danger"
                              id="tooltip320351170"
                              size="sm"
                              title=""
                              type="button"
                            >
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip320351170"
                              >
                                Possível Erro
                              </UncontrolledTooltip>                              
                              <i className="tim-icons icon-simple-remove" />
                            </Button>
                          : "#"}

                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">
                          <div className="photo">
                            <img
                              alt="..."
                              src={logoEmpresa != '' ? logoEmpresa: require("~/assets/img/SemImagem.jpg")}
                            />
                          </div>
                        </td>
                        <td>{empresaNome}</td>
                        <td>{objUltimasIntegracoes != null ? objUltimasIntegracoes.dataInicio[3]: "#"}</td>
                        <td>{objUltimasIntegracoes != null ? objUltimasIntegracoes.dataFinal[3]: "#"}</td>
                        <td className="text-center">
                          {objUltimasIntegracoes != null ? 
                            objUltimasIntegracoes.statusIntegracao[3] === "OK" ? 
                            
                             <Button
                              className="btn-link btn-icon"
                              color="success"
                              id="tooltip345411997"
                              size="sm"
                              title=""
                              type="button"
                            >
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip345411997"
                              >
                                Sucesso
                              </UncontrolledTooltip>                              
                              <i className="tim-icons icon-check-2" />
                            </Button>
                            : 
                            <Button
                              className="btn-link btn-icon"
                              color="danger"
                              id="tooltip601343171"
                              size="sm"
                              title=""
                              type="button"
                            >
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip601343171"
                              >
                                Possível Erro
                              </UncontrolledTooltip>                              
                              <i className="tim-icons icon-simple-remove" />
                            </Button>
                          : "#"}

                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">
                          <div className="photo">
                            <img
                              alt="..."
                              src={logoEmpresa != '' ? logoEmpresa: require("~/assets/img/SemImagem.jpg")}
                            />
                          </div>
                        </td>
                        <td>{empresaNome}</td>
                        <td>{objUltimasIntegracoes != null ? objUltimasIntegracoes.dataInicio[4]: "#"}</td>
                        <td>{objUltimasIntegracoes != null ? objUltimasIntegracoes.dataFinal[4]: "#"}</td>
                        <td className="text-center">
                          {objUltimasIntegracoes != null ? 
                            objUltimasIntegracoes.statusIntegracao[4] === "OK" ? 
                            
                             <Button
                              className="btn-link btn-icon"
                              color="success"
                              id="tooltip774891382"
                              size="sm"
                              title=""
                              type="button"
                            >
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip774891382"
                              >
                                Sucesso
                              </UncontrolledTooltip>                              
                              <i className="tim-icons icon-check-2" />
                            </Button>
                            : 
                            <Button
                              className="btn-link btn-icon"
                              color="danger"
                              id="tooltip949929353"
                              size="sm"
                              title=""
                              type="button"
                            >
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip949929353"
                              >
                                Possível Erro
                              </UncontrolledTooltip>                              
                              <i className="tim-icons icon-simple-remove" />
                            </Button>
                          : "#"}

                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">
                          <div className="photo">
                            <img
                              alt="..."
                              src={logoEmpresa != '' ? logoEmpresa: require("~/assets/img/SemImagem.jpg")}
                            />
                          </div>
                        </td>
                        <td>{empresaNome}</td>
                        <td>{objUltimasIntegracoes != null ? objUltimasIntegracoes.dataInicio[5]: "#"}</td>
                        <td>{objUltimasIntegracoes != null ? objUltimasIntegracoes.dataFinal[5]: "#"}</td>
                        <td className="text-center">
                          {objUltimasIntegracoes != null ? 
                            objUltimasIntegracoes.statusIntegracao[5] === "OK" ? 
                            
                             <Button
                              className="btn-link btn-icon"
                              color="success"
                              id="tooltip30547133"
                              size="sm"
                              title=""
                              type="button"
                            >
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip30547133"
                              >
                                Sucesso
                              </UncontrolledTooltip>                              
                              <i className="tim-icons icon-check-2" />
                            </Button>
                            : 
                            <Button
                              className="btn-link btn-icon"
                              color="danger"
                              id="tooltip156899243"
                              size="sm"
                              title=""
                              type="button"
                            >
                              <UncontrolledTooltip
                                delay={0}
                                target="tooltip156899243"
                              >
                                Possível Erro
                              </UncontrolledTooltip>                              
                              <i className="tim-icons icon-simple-remove" />
                            </Button>
                          : "#"}

                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col> */}     

          </Row>
        </div>
      </>
    );
  //}
}

export default Dashboard;

import React, { useState, useEffect, useRef } from 'react';
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { ModalBasic, ModalBasicBody, ModalBasicFooter } from "~/components/ModalBasic/ModalBasic";
import * as controllerPedidoVendas from "~/controller/Vendas/controllerPedidoVendas"
import '~/views/Vendas/Pedido/Modals/styles.css'
import { maskNumbers } from "~/Util/mascaras";

import {
    Input,
    Row,
    Col,
    Button,
} from "reactstrap";


const ModalReabrirExcluir = ({isOpen, toggleModalReabrirExcluir, pedidoPedido, paramTela, shouldClean}) => {
    const inputRef = useRef(null);

    const login = useSelector((state) => state.login);
    const notificacao = useSelector((state) => state.notificacao);
    const [loading, setLoading] = useState(false);

    const [usuarioPedido, setusuarioPedido] = useState({idPedido: '', idEmpresa: '', usuario: '', senha: '', motivo: '', mesaComanda: '' })
    const dispatch = useDispatch();

    useEffect(() => {
      if (pedidoPedido !== null){
        setusuarioPedido({ ...usuarioPedido, idPedido: pedidoPedido.idPedido,
                                         idEmpresa: pedidoPedido.idEmpresa,
                                         usuario: pedidoPedido.nomeUsuarioVenda,
                                         senha: '',
                                         motivo: '',
                                         mesaComanda: pedidoPedido.mesaComanda,
         })
      }
      if (inputRef.current) {
        inputRef.current.focus();
      } 
    }, [])
 
    function handleVoltar(){
      toggleModalReabrirExcluir();
    }

    
    async function handleOnclick(){
      if (paramTela.acaoTela === 'R'){
        handleReabrirExcluir("ABERTO");    
      }else if (paramTela.acaoTela === 'E'){
        handleReabrirExcluir("EXCLUÍDO");
      }
    }

/*     async function handleReabrir(){
      setLoading(true);
      if (!loading) {
        await controllerPedidoVendas.TrocarMesaComanda(notificacao, dispatch, usuarioPedido, "ABERTO", () => {

        });  
      }
      setLoading(false);
      toggleModalReabrirExcluir();
    }
 */
    async function handleReabrirExcluir(mensagem){
      setLoading(true);
      if (!loading) {
        await controllerPedidoVendas.ExcluirReabrirMesaComanda(notificacao, dispatch, usuarioPedido, mensagem, paramTela.acaoTela, () => {

        });  
      }
      setLoading(false);
      toggleModalReabrirExcluir();
    }

    function handleOnChangeUsuario(value, campo) {
      setusuarioPedido({ ...usuarioPedido, usuario: value }) 
    }
  
    function handleOnChangeSenha(value, campo) {
      setusuarioPedido({ ...usuarioPedido, senha: value }) 
    }

    return (
          <ModalBasic
            id="modalItem"
            isOpen={isOpen}
            toggle={() => toggleModalReabrirExcluir()}
            fontSize='30px'
            title={paramTela.titulo}
            size="md" // sm, md, lg, xl = TAMANHO da Modal
          > 
            <ModalBasicBody>
                <Row className="padding-padrao-row">
                  <Col className="padding-padrao-col">
                    <label>Mesa/Comanda</label>
                      <Input
                          style={{textTransform:"uppercase" , fontSize: '22px', height: '50px', textAlign:'center' }}
                          id='mesaComanda'
                          type='text'
                          autoComplete='off'
                          value={pedidoPedido.mesaComanda}
                          disabled
                      />
                  </Col>
                </Row>  
                <Row className="padding-padrao-row">
                  <Col className="padding-padrao-col">
                    <label>Usuário</label>
                      <Input
                          ref={inputRef} 
                          style={{textTransform:"uppercase" , fontSize: '16px', textAlign:'center' }}
                          id='usuario'
                          type='text'
                          autoComplete='off'
                          value={usuarioPedido.usuario}
                          onChange={(e) =>
                            handleOnChangeUsuario(e.target.value, e.target.id)
                          }
                          onFocus={e => e.target.select()}
                          maxLength="15"
                      />
                  </Col>                  
                  <Col className="padding-padrao-col">
                    <label>Senha</label>
                      <Input
                          style={{textTransform:"uppercase" , fontSize: '16px', textAlign:'center' }}
                          id='mesaPara'
                          type='password'
                          autoComplete='off'
                          value={usuarioPedido.senha}
                          onChange={(e) =>
                            handleOnChangeSenha(e.target.value, e.target.id)
                          }
                          onFocus={e => e.target.select()}
                          maxLength="15"
                          
                      />
                  </Col>                  

                </Row>    
            </ModalBasicBody>

            <ModalBasicFooter>
                <Row className="justify-content-center">
                    <Col lg md sm>
                        <Col className="text-center">
                            <Button
                                className='btn btn-green'
                                //color="info"
                                type="button"
                                onClick={handleVoltar}
                                style={{ width: '100px' }}
                            >Voltar
                            </Button>
                            <Button
                                color="info"
                                type="button"
                                onClick={() => handleOnclick()}
                                style={{ width: '100px' }}
                            >Confirmar
                            </Button>
                        </Col>
                    </Col>
                </Row>
            </ModalBasicFooter>
          
        </ModalBasic>
      
    )
}

export default ModalReabrirExcluir
import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { ModalBasic, ModalBasicBody, ModalBasicFooter } from "~/components/ModalBasic/ModalBasic";
import * as controllerPedidoVendas from "~/controller/Vendas/controllerPedidoVendas"
import '~/views/Vendas/Pedido/Modals/styles.css'
import { maskMoeda,removeMaskMoney } from "~/Util/mascaras";
import { alertar } from "~/Util/alertar";

import {
    Input,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    CardTitle,
    FormGroup,
    CardFooter, 
    NavItem,
    NavLink,
    Nav,  
} from "reactstrap";


const ModalPgtoParcial = ({isOpen, toggleModalPgtoParcial, pedidoPagamentoParcial, shouldClean}) => {

  const { invalidoPagamentoParcial } = useSelector((state) => state.PedidoItem);
    const login = useSelector((state) => state.login);
    const notificacao = useSelector((state) => state.notificacao);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    // Estado para controlar a cor de fundo
    const corFormaSelecionada = '#6e6e6e'; 
    const corFormaNormal = '#cecece';

    const [bgColor, setBgColor] = useState(corFormaNormal); // Cor inicial
    const [bgColorDinheiro, setBgColorDinheiro] = useState(corFormaNormal); // Cor inicial
    const [bgColorDebito, setBgColorDebito] = useState(corFormaNormal); // Cor inicial
    const [bgColorCredito, setBgColorCredito] = useState(corFormaNormal); // Cor inicial

    useEffect(() => {
      inicializaValores();      
    }, [])

    

    // Função para alternar a cor de fundo ao clicar e armazenar a forma de pagamento
    const toggleBackgroundColor = (e, cardName) => {
      setBgColor(prevColor => (prevColor === corFormaNormal ? corFormaSelecionada : corFormaNormal));
      setBgColorDinheiro(corFormaNormal);
      setBgColorDebito(corFormaNormal);
      setBgColorCredito(corFormaNormal);

      inicializaValores(cardName);

      pedidoPagamentoParcial.idForma = '2';
      if (bgColor === corFormaSelecionada){
        pedidoPagamentoParcial.idForma = '';
      }
      let idStore = 'PEDIDO'
      let type = 'PEDIDO_PAGAMENTO_PARCIAL'

      dispatch({ idStore, type, campo: 'idForma', value: pedidoPagamentoParcial.idForma })

      document.getElementById("valorPago").focus();

    };

    // Função para alternar a cor de fundo ao clicar e armazenar a forma de pagamento
    const toggleBackgroundColorDinheiro = (e, cardName) => {
      setBgColor(corFormaNormal);
      setBgColorDinheiro(prevColor => (prevColor === corFormaNormal ? corFormaSelecionada : corFormaNormal));
      setBgColorDebito(corFormaNormal);
      setBgColorCredito(corFormaNormal);

      inicializaValores(cardName);
      //document.getElementById("valorPagoDigitado").focus();
      
      pedidoPagamentoParcial.idForma = '1';
      if (bgColorDinheiro === corFormaSelecionada){
        pedidoPagamentoParcial.idForma = '';
      }
      let idStore = 'PEDIDO'
      let type = 'PEDIDO_PAGAMENTO_PARCIAL'

      dispatch({ idStore, type, campo: 'idForma', value: pedidoPagamentoParcial.idForma })

      document.getElementById("valorPago").focus();

    };

    // Função para alternar a cor de fundo ao clicar e armazenar a forma de pagamento
    const toggleBackgroundColorDebito = (e, cardName) => {
      setBgColor(corFormaNormal);
      setBgColorDinheiro(corFormaNormal);
      setBgColorDebito(prevColor => (prevColor === corFormaNormal ? corFormaSelecionada : corFormaNormal));
      setBgColorCredito(corFormaNormal);

      inicializaValores(cardName);

      pedidoPagamentoParcial.idForma = '3';
      if (setBgColorDebito === corFormaSelecionada){
        pedidoPagamentoParcial.idForma = '';
      }
      let idStore = 'PEDIDO'
      let type = 'PEDIDO_PAGAMENTO_PARCIAL'

      dispatch({ idStore, type, campo: 'idForma', value: pedidoPagamentoParcial.idForma })

      document.getElementById("valorPago").focus();

    };

    // Função para alternar a cor de fundo ao clicar e armazenar a forma de pagamento
    const toggleBackgroundColorCredito = (e, cardName) => {
      setBgColor(corFormaNormal);
      setBgColorDinheiro(corFormaNormal);
      setBgColorDebito(corFormaNormal);
      setBgColorCredito(prevColor => (prevColor === corFormaNormal ? corFormaSelecionada : corFormaNormal));

      inicializaValores(cardName);

      pedidoPagamentoParcial.idForma = '4';
      if (setBgColorCredito === corFormaSelecionada){
        pedidoPagamentoParcial.idForma = '';
      }
      let idStore = 'PEDIDO'
      let type = 'PEDIDO_PAGAMENTO_PARCIAL'

      dispatch({ idStore, type, campo: 'idForma', value: pedidoPagamentoParcial.idForma })

      document.getElementById("valorPago").focus();

    };


    function inicializaValores(cardName){
      let idStore = 'PEDIDO'
      let type = 'PEDIDO_PAGAMENTO_PARCIAL'

      dispatch({ idStore, type, campo: 'valorPago', value: '0,00' })
      dispatch({ idStore, type, campo: 'nome', value: '' })
    }


    /*-----------------------------------------------------------------
    * ALTERAÇÃO DE CAMPOS
    *-----------------------------------------------------------------*/
    function handleOnChange(campo, value) {
      let idStore = 'PEDIDO'
      dispatch({ idStore, type: "PEDIDO_PAGAMENTO_PARCIAL", campo, value });
    }


    function handleVoltar(){
      let idStore = 'PEDIDO'
      dispatch({ idStore, type: "CLEAN_PEDIDO_PAGAMENTO_PARCIAL" });

      toggleModalPgtoParcial();
    }

    async function handleGravar(){
      let idStore = 'PEDIDO'
      if (pedidoPagamentoParcial.idForma === ''){
        alertar(notificacao, 'br', "SELECIONE a Forma de Pagamento.", 'info', 'icon-alert-circle-exc', 2);
      }else if (pedidoPagamentoParcial.nome === ''){
        dispatch({ idStore, type: 'INVALIDO_PAGAMENTO_PARCIAL', campo: 'nome', value: true })
        alertar(notificacao, 'br', "Informe o NOME de quem estar PAGANDO.", 'info', 'icon-alert-circle-exc', 2);
      }else if (pedidoPagamentoParcial.valorPago === '' || pedidoPagamentoParcial.valorPago === '0,00'){
        dispatch({ idStore, type: 'INVALIDO_PAGAMENTO_PARCIAL', campo: 'valorPago', value: true })
        alertar(notificacao, 'br', "Informe o VALOR.", 'info', 'icon-alert-circle-exc', 2);
      }
      else{
        setLoading(true);
        if (!loading) {

          await controllerPedidoVendas.IncluirPagamentoParcial(notificacao, dispatch, login.usuario, pedidoPagamentoParcial, () => {
  
          });  
        }
        setLoading(false);
  
        let idStore = 'PEDIDO'
        dispatch({ idStore, type: "CLEAN_PEDIDO_PAGAMENTO_PARCIAL" });
        dispatch({ idStore, type: "CLEAN_INVALIDO_PAGAMENTO_PARCIAL" });

        toggleModalPgtoParcial();  
      }
    }

    function handleKeyPress(event, campo) {
      if (event.key === 'Enter' || event.key === 'Tab') {
        if (campo === 'valorPago'){
          document.getElementById("nome").focus();
        }else if (campo === 'nome'){
          document.getElementById("valorPago").focus();
        }
      }
    }

    return (
          <ModalBasic
            id="modalItem"
            isOpen={isOpen}
            toggle={() => toggleModalPgtoParcial()}
            title={'Pagamento Parcial'}
            size="lg" // sm, md, lg, xl = TAMANHO da Modal
            style={{backgroundColor: "silver"}}
          > 
            <ModalBasicBody>
                <Row md="12" className="padding-padrao-row">
                  <Col md="5" className="padding-padrao-col" >
                    <fieldset className="fieldset-resultados" style={{ height: '100%' }}>
                      <legend>Formas de Pagamento</legend>
                        <Row className="padding-padrao-row" style={{ marginTop: '20px'}}>
                          <Col>
                            <Card className="card-stats" 
                              style={{ backgroundColor: bgColor, cursor: 'pointer' }} // Define a cor dinamicamente
                              onClick={(e) =>
                                toggleBackgroundColor(e, "PIX")
                              }                            
                            >
                              <CardBody>
                                <Row>
                                  <Col xs="3">                                  
                                    <div className="photo" style={{ width: '50px', height: '50px' }}>
                                      <img
                                        alt="..."
                                        src={require("~/assets/img/imagens/pix.png")}
                                      />
                                    </div>
                                  </Col>
                                  <Col xs="9">
                                    <div className="numbers">
                                      <p className="card-category">Pagar com</p>
                                      <CardTitle tag="h4">PIX</CardTitle>
                                    </div>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>                                  

                          </Col>
                        </Row>

                        <Row className="padding-padrao-row" style={{ marginTop: '-25px' }} >
                          <Col>
                            <Card className="card-stats"
                              style={{ backgroundColor: bgColorDinheiro, cursor: 'pointer' }} // Define a cor dinamicamente
                              onClick={(e) =>
                                toggleBackgroundColorDinheiro(e, "DINHEIRO")
                              }                            

                            >
                              <CardBody>
                                <Row>
                                  <Col xs="3">
                                    <div className="photo" style={{ width: '50px', height: '50px' }}>
                                      <img
                                        alt="..."
                                        src={require("~/assets/img/imagens/dinheiro.png")}
                                      />
                                    </div>
                                  </Col>
                                  <Col xs="9">
                                    <div className="numbers">
                                      <p className="card-category">Pagar com</p>
                                      <CardTitle tag="h4">DINHEIRO</CardTitle>
                                    </div>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>

                        <Row className="padding-padrao-row" style={{ marginTop: '-25px' }}>
                          <Col>
                            <Card className="card-stats"
                              style={{ backgroundColor: bgColorDebito, cursor: 'pointer' }} // Define a cor dinamicamente
                              onClick={(e) =>
                                toggleBackgroundColorDebito(e, "DEBITO")
                              }                                                        
                            >
                              <CardBody>
                                <Row>
                                  <Col xs="3">
                                    <div className="photo" style={{ width: '50px', height: '50px' }}>
                                      <img
                                        alt="..."
                                        src={require("~/assets/img/imagens/cartao_debito_cinza.png")}
                                      />
                                    </div>
                                  </Col>
                                  <Col xs="9">
                                    <div className="numbers">
                                      <p className="card-category">Pagar com</p>
                                      <CardTitle tag="h4">CARTÃO DÉBITO</CardTitle>
                                    </div>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>

                        <Row className="padding-padrao-row" style={{ marginTop: '-25px' }}>
                          <Col>
                            <Card className="card-stats"
                              style={{ backgroundColor: bgColorCredito, cursor: 'pointer' }} // Define a cor dinamicamente
                              onClick={(e) =>
                                toggleBackgroundColorCredito(e, "CREDITO")
                              }                                                        
                            >
                              <CardBody>
                                <Row>
                                  <Col xs="3">
                                    <div className="photo" style={{ width: '50px', height: '50px' }}>
                                      <img
                                        alt="..."
                                        src={require("~/assets/img/imagens/cartao_credito.png")}
                                      />
                                    </div>
                                  </Col>
                                  <Col xs="9">
                                    <div className="numbers">
                                      <p className="card-category">Pagar com</p>
                                      <CardTitle tag="h4">CARTÃO CRÉDITO</CardTitle>
                                    </div>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>

                    </fieldset>    
                  </Col>

                  <Col className="padding-padrao-col">
                    <fieldset className="fieldset-resultados" style={{ height: '100%' }}>
                      <legend>Informações do Pagamento</legend>
                        <Row className="padding-padrao-row">
                          <Col className="padding-padrao-col" lg="5" md="1" style={{ marginTop: '0px' }}>
                            <Input
                              id="labelValorParcial"
                              type="text"
                              autoComplete="off"
                              disabled
                              style={{ fontSize: '18px', textAlign: 'left', backgroundColor: '#969696' }}
                              value="Valor R$"
                            />
                          </Col>
     
                          <Col className="padding-padrao-col" lg="7" md="2" style={{ marginTop: '0px' }}>
                            <Input
                              tabIndex={1}
                              style={{textAlign: 'right', textTransform:"uppercase" , fontSize: '22px', backgroundColor: '#e6e159' }}
                              borderTopRightRadius="0"
                              id="valorPago"
                              type="text"
                              autoComplete="off"
                              value={pedidoPagamentoParcial.valorPago}
                              onChange={(e) =>
                                handleOnChange(e.target.id, maskMoeda(e.target.value))
                              }
                              onFocus={e => e.target.select()}
                              onKeyPress={(e) => {
                                if (e.target.value !== ''){
                                  handleKeyPress(e, e.target.id);
                                  //if (e.key === 'Enter') e.target.select()  
                                }
                              }}
                              maxLength="15"
                            />
                          </Col>          
                        </Row>
                        <Row className="padding-padrao-row">
                          <Col className="padding-padrao-col" lg="5" md="1" style={{ marginTop: '0px' }}>
                            <Input
                              id="labelNome"
                              type="text"
                              autoComplete="off"
                              disabled
                              style={{ fontSize: '18px', textAlign: 'left', backgroundColor: '#969696' }}
                              value="Cliente"
                            />
                          </Col>
     
                          <Col className="padding-padrao-col" lg="7" md="2" style={{ marginTop: '0px' }}>
                            <FormGroup className={invalidoPagamentoParcial.nome ? "has-danger" : ''}>
                              <Input
                                tabIndex={2}
                                color='#000000'
                                style={{textAlign: 'right', textTransform:"uppercase" , fontSize: '22px', backgroundColor: '#e6e159' }}
                                borderTopRightRadius="0"
                                id="nome"
                                type="text"
                                autoComplete="off"
                                value={pedidoPagamentoParcial.nome}
                                onChange={(e) =>
                                  handleOnChange(e.target.id, e.target.value)
                                }
                                onFocus={e => e.target.select()}
                                onKeyPress={(e) => {
                                  if (e.target.value !== ''){
                                    handleKeyPress(e, e.target.id);
                                    //if (e.key === 'Enter') e.target.select()  
                                  }
                                }}
                                maxLength="15"
                              />
                            </FormGroup>  
                          </Col>          
                        </Row>                            
                    </fieldset>    
                  </Col>
                </Row>


            </ModalBasicBody>

            <ModalBasicFooter>
                <Row className="justify-content-center">
                    <Col lg md sm>
                        <Col className="text-center">
                            <Button
                                className='btn btn-green'
                                //color="info"
                                type="button"
                                onClick={handleVoltar}
                                style={{ width: '130px' }}
                            >Voltar
                            </Button>
                            <Button
                                color="success"
                                type="button"
                                onClick={() => handleGravar()}
                                style={{ width: '130px' }}
                            >Confirmar
                            </Button>
                        </Col>
                    </Col>
                </Row>
            </ModalBasicFooter>
          
        </ModalBasic>
      
    )
}

export default ModalPgtoParcial
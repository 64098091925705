import axios from "axios"
import api from "~/Util/api.js";
import { alertar } from "~/Util/alertar";
import {maskMoeda, removerZerosEsquerda, adicionarZerosEsquerda, formatarData, removeMaskMoney} from "~/Util/mascaras";

const idStore = 'PEDIDO';

/*--------------------------------------------------------------------------------------- 
 * BUSCA INFORMAÇÕES DO PRODUTO QUE FOI DIGITADO
 *---------------------------------------------------------------------------------------*/
export async function GetProduto(notificacao, dispatch, idEmpresa, idProduto, thenFunction){
  try {
    
        var ulrPesquisa = '';

        var codigo = '-' //ID_PRODUTO ou CODIGO_PRODUTO
        var descricao = '-'
  
        if (!isNaN(parseFloat(idProduto)) && isFinite(idProduto)) {
          codigo = idProduto;
        }else if (idProduto !== '' && idProduto !== undefined){
          descricao = idProduto;
        }

        ulrPesquisa = api.urlBaseChefe + api.urlCadastroProdutoGetProdutosPesquisa + `${idEmpresa}` + `/${codigo}` + `/${descricao}`;

        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: ulrPesquisa,
        headers: { 
            'Authorization': bearerAuth
        }
        };
        
        axios.request(config)
        .then((response) => {
          if (response.data.message === 'Sucesso') {
            let idStore = 'PEDIDO_PESQ_PRODUTO';             

            if (response.data.data.length === 0){
              alertar(notificacao, 'br', "Nenhum Produto encontrado", 'info', 'icon-bulb-63', 2)  
              return;
            }else if (response.data.data.length === 1){
              CarregarProduto(notificacao, dispatch, response.data.data, 0)
            }else{
              dispatch({ idStore, type: 'PRODUTOs', value: response.data.data })        
            }

            thenFunction()
              
          }else{
            alertar(notificacao, 'br', "Nenhum Produto encontrado", 'info', 'icon-bulb-63', 2)
          }
        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
    }

}

/*--------------------------------------------------------------------------------------- 
 * BUSCAR MESA/COMANDA/DELIVERY POR:
 * mesaComanda = numero da mesa, número da comanda ou número do telefone
 * idPedido = id do pedido
 *---------------------------------------------------------------------------------------*/
export async function GetMesaComanda(notificacao, dispatch, idEmpresa, mesaComanda, idPedido, thenFunction){
  try {
    
        var ulrPesquisa = '';

        mesaComanda = removerZerosEsquerda(mesaComanda);
        var pesqMesaComanda = "-"
        var pesqIdPedido = "-"

        if (mesaComanda !== ''){
          pesqMesaComanda = mesaComanda;
        }else if (idPedido !== ''){
          pesqIdPedido = idPedido;
        }

        ulrPesquisa = api.urlBaseChefe + api.urlPedidoPesquisarMesaComanda + `${idEmpresa}` + `/${pesqMesaComanda}` + `/${pesqIdPedido}`;

        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: ulrPesquisa,
        headers: { 
            'Authorization': bearerAuth
        }
        };
        
        axios.request(config)
        .then((response) => {
          if (response.data.message === 'Sucesso') {
            
            if (response.data.data.idPedido !== 0){
              CarregarPedidoItens(notificacao, dispatch, response.data.data);
            }

            thenFunction()
              
          }else{
            alertar(notificacao, 'br', "Nenhum Pedido encontrado", 'info', 'icon-bulb-63', 2)
          }
        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
    }

}


/*--------------------------------------------------------------------------------------- 
 * BUSCAR TODAS AS MESAS/COMANDAS/DELIVERY DA EMPRESA (ABERTAS E LIVRES)
 * Parametros:
 * idEmpresa
 * Quantidade de Mesas que utiliza
 * Status do Pedido
 * MesasComandas que pode ser M, C, S, MC, MS, CS ou MCS (Mesa/Comanda/Sequencia)
 *------------------------------------------------
 * Filtro Pesquisa:
 * Data Inicial
 * Data Final
 * Número do Pedido = idPedido
 * Nome Cliente
 *---------------------------------------------------------------------------------------*/
export async function GetMesasComandasDelivery(notificacao, dispatch, login, pesquisa, thenFunction){
  try {
    
        var ulrPesquisa = '';

        var idEmpresa = login.empresa;
        var statusPedido = '';
        var dataInicio = pesquisa.dataInicio;
        var dataFinal = pesquisa.dataFinal;
        var formaPagamento = 0;
        var idCaixa = 0;
        let dataPesquisa = '';
        
        if (pesquisa.dataInicio === '' && pesquisa.dataFinal === ''){
          dataPesquisa = new Date().toISOString().substr(0, 10);
          dataInicio = dataPesquisa;
          dataFinal = dataPesquisa;  
        }

        if (pesquisa.pedidoAberto === ''){
          statusPedido = 'S';
        }else{
          statusPedido = pesquisa.pedidoAberto;
        }

        //ulrPesquisa = api.urlBaseChefe + api.urlPedidoMesasComandasDelivery + `${idEmpresa}` + `/${statusPedido}`+ `/${quantidadeMesas}` + `/${mesaComandaSequencia}`;
        ulrPesquisa = api.urlBaseChefe + api.urlPedidoConsultaDataForma + `${idEmpresa}` + `/${dataInicio}` + `/${dataFinal}` + `/${formaPagamento}`+ `/${statusPedido}`+ `/${idCaixa}`;
        

        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: ulrPesquisa,
        headers: { 
            'Authorization': bearerAuth
        }
        };
        
        axios.request(config)
        .then((response) => {
          if (response.data.message === 'Sucesso') {
            
            if (response.data.data.length !== 0){
              var pedidos = response.data.data;
              var qtdPedidos = response.data.data.length;  
              let pedidosRetorno = [];
              for (var i = 0; i < qtdPedidos; i++) {
                if (pedidos[i].idPedido !== 0){
                  if (pedidos[i].tipoVenda === 'B'){
                    pedidos[i].tipoVenda = 'BALCÃO'
                  }else if (pedidos[i].tipoVenda === 'E'){
                    pedidos[i].tipoVenda = 'ENTREGA'
                  }

                  if (pedidos[i].tipoPedido === 'M'){
                    pedidos[i].tipoPedido = 'MESA'
                  }else if (pedidos[i].tipoPedido === 'C'){
                    pedidos[i].tipoPedido = 'COMANDA'
                  }else if (pedidos[i].tipoPedido === 'S'){
                    pedidos[i].tipoPedido = 'PEDIDO'
                  }else if (pedidos[i].tipoPedido === 'D'){
                    pedidos[i].tipoPedido = 'DELIVERY'
                  }

                  if (pedidos[i].pedidoAberto === 'S'){
                    pedidos[i].pedidoAberto = 'ABERTO'
                  }else if (pedidos[i].pedidoAberto === 'N'){
                    if (pedidos[i].status === '9'){
                      pedidos[i].pedidoAberto = 'EXCLUÍDO'
                    } else{
                      pedidos[i].pedidoAberto = 'FECHADO'
                    }
                  }

                  pedidosRetorno.push(pedidos[i]);
                }
              }

              dispatch({ idStore, type: 'PEDIDOs', value: pedidosRetorno })
            }

            thenFunction()
              
          }else{
            alertar(notificacao, 'br', "Nenhum Pedido encontrado", 'info', 'icon-bulb-63', 2)
          }
        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
    }

}

/*--------------------------------------------------------------------------------------- 
 * CARREGA INFORMAÇÕES DO PEDIDO E ITENS (MESA/COMANDA/DELIVERY) QUE FOI DIGITADO
 *---------------------------------------------------------------------------------------*/
export function CarregarPedidoItens(notificacao, dispatch, pedidoItens){
  try{

    let type = 'PEDIDO';
    dispatch({ idStore, type, campo: 'idPedido', value: pedidoItens.idPedido })
    dispatch({ idStore, type, campo: 'idEmpresa', value: pedidoItens.idEmpresa })
    dispatch({ idStore, type, campo: 'idCliente', value: pedidoItens.idCliente })
    dispatch({ idStore, type, campo: 'idUsuario', value: pedidoItens.idUsuario })
    dispatch({ idStore, type, campo: 'dataPedido', value: pedidoItens.dataPedido })
    dispatch({ idStore, type, campo: 'mesaComanda', value: pedidoItens.mesaComanda })
    dispatch({ idStore, type, campo: 'mesaComandaVinculada', value: pedidoItens.mesaComandaVinculada })
    dispatch({ idStore, type, campo: 'observacao', value: pedidoItens.observacao })
    dispatch({ idStore, type, campo: 'tipoVenda', value: pedidoItens.tipoVenda })
    dispatch({ idStore, type, campo: 'tipoPedido', value: pedidoItens.tipoPedido })
    dispatch({ idStore, type, campo: 'percentualTaxa', value: pedidoItens.percentualTaxa })
    dispatch({ idStore, type, campo: 'valorTaxa', value: maskMoeda(pedidoItens.valorTaxa.toFixed(2)) })
    //dispatch({ idStore, type, campo: 'valorEntrega', value: pedidoItens.valorEntrega })
    dispatch({ idStore, type, campo: 'valorSubTotal', value: maskMoeda(pedidoItens.valorSubTotal.toFixed(2)) })
    dispatch({ idStore, type, campo: 'valorDesconto', value: maskMoeda(pedidoItens.valorDesconto.toFixed(2)) })
    dispatch({ idStore, type, campo: 'valorAcrescimo', value: maskMoeda(pedidoItens.valorAcrescimo.toFixed(2)) })
    dispatch({ idStore, type, campo: 'valorParcialTotal', value: maskMoeda(pedidoItens.valorParcialTotal.toFixed(2)) })
    dispatch({ idStore, type, campo: 'valorTotal', value: maskMoeda(pedidoItens.valorTotal.toFixed(2)) })

    var valorTotal = 0;
    var valorParcialTotal = 0;
    var valorTotalMenosParcial = 0;

    valorTotal = pedidoItens.valorTotal;
    valorParcialTotal = pedidoItens.valorParcialTotal;

    valorTotalMenosParcial = Number(valorTotal) - Number(valorParcialTotal);
    valorTotalMenosParcial = valorTotalMenosParcial.toFixed(2);

    dispatch({ idStore, type, campo: 'valorTotalMenosParcial', value: maskMoeda(valorTotalMenosParcial) })

    dispatch({ idStore, type, campo: 'pedidoAberto', value: pedidoItens.pedidoAberto })
    dispatch({ idStore, type, campo: 'enderecoEntrega', value: pedidoItens.enderecoEntrega })
    dispatch({ idStore, type, campo: 'nfceCancelada', value: pedidoItens.nfceCancelada })
    dispatch({ idStore, type, campo: 'status', value: pedidoItens.status })

    dispatch({ idStore, type, campo: 'nomeCliente', value: pedidoItens.nomeCliente })
    dispatch({ idStore, type, campo: 'nomeUsuarioVenda', value: pedidoItens.nomeUsuarioVenda })

    dispatch({ idStore, type: 'PEDIDOITENs', value: pedidoItens.pedidoItem })

  } catch (error) {
    alertar(notificacao, 'br', "Não foi possível carregar o pedido, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
  }
}


/*--------------------------------------------------------------------------------------- 
 * Adiciona Produto (Uma a Um)
 *---------------------------------------------------------------------------------------*/
export async function AddItem(notificacao, dispatch, login, pedido, item, enderecoEntrega, thenFunction){
    try {

      let ulr = '';
      let tipoVenda = 'B'; //Balcão
      let tipoPedido = 'M'; //Mesa
      if (enderecoEntrega.idCliente !== ''){
        tipoVenda = 'E';
        tipoPedido = 'D';
      }

      const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');

      ulr = api.urlBaseChefe + api.urlPedidoIncluirAlterar; 

      var invalido = false;
      if (pedido.mesaComanda === '') {
          dispatch({ idStore, type: 'INVALIDO', campo: 'mesaComanda', value: true })
          alertar(notificacao, 'br', "Informe uma MESA ou COMANDA.", 'warning', 'icon-alert-circle-exc', 2);
          invalido = true;
          return true;
      }

      // if (JSON.stringify(pedido) === JSON.stringify(pedidoCopia)) {
      //   alertar(notificacao, 'br', "Nenhuma Alteraçõe foi Feita no Cadastro.", 'info', 'icon-bulb-63', 2);
      //   return false
      // }        

      if (pedido.idPedido === ''){
        pedido.idPedido = 0;
        pedido.idEmpresa = login.empresa;
        pedido.idUsuario = login.idUsuarioLogin;
        if (pedido.idCliente === ''){
          pedido.idCliente = 0;
        }
        pedido.percentualTaxa = 0;
        pedido.valorTaxa = 0;
        pedido.tipoVenda = tipoVenda;
        pedido.tipoPedido = tipoPedido; //Vem da tela de poupup de escolha de tipo de venda (M=MESA;C=COMANDA;S=SEQUENCIAL;D=DELIBERY)
        if (pedido.tipoPedido === 'M'){
          pedido.mesaComanda = ("000" + pedido.mesaComanda).slice(-3)
        }else if (pedido.tipoPedido === 'C'){
          pedido.mesaComanda = ("0000" + pedido.mesaComanda).slice(-4)
        }
        pedido.valorSubTotal = 0;
        pedido.valorTotal = 0;
        pedido.pedidoAberto = 'S';
        pedido.enderecoEntrega = '';
        pedido.status = '1';
      }

      var valorTotalItem = 0;
      var valorItem = 0;
      valorItem = removeMaskMoney(item.valorProduto)
      valorTotalItem = item.quantidade * valorItem;

      const itemDigitado = []
      itemDigitado.push({
        idPedidoItem: 0,
        idEmpresa: login.empresa,
        idPedido: pedido.idPedido,
        IdProduto_Item: item.idProduto,
        dataItem: '',
        quantidade: item.quantidade,
        valorItem: valorItem,
        valorTotal: valorTotalItem,
        observacaoItem: item.observacao,
        impresso: 'N',
        produzido: 'N',
        integrado: 'N',
        status: '1',
      });

      pedido.pedidoItem = itemDigitado;      

      if (enderecoEntrega.idCliente !== ''){
        const endereco = {
          idEndereco: enderecoEntrega.idEndereco,
          idCliente: enderecoEntrega.idCliente,
          cep: enderecoEntrega.cep,
          endereco: enderecoEntrega.endereco,
          bairro: enderecoEntrega.bairro,
          cidade: enderecoEntrega.cidade,
          uf: enderecoEntrega.uf,
          numero: enderecoEntrega.numero,
          complemento: enderecoEntrega.complemento,
          status: enderecoEntrega.status,
        };

        pedido.clienteEnderecoEntrega = endereco;

      }

      return await axios({
        method: 'POST',
        url: ulr,
        headers: {"Content-Type": "application/json", 'Authorization': bearerAuth,},
        data: {
          pedido: pedido,
        }
      }).then(response => {
          if (response.data.message === "Sucesso"){

            //Limpa Objsetos
            let idStore = 'PEDIDO';
            dispatch({ idStore, type: 'CLEAN_PEDIDO' });
            dispatch({ idStore, type: 'PEDIDOITENs', value: [] });
            dispatch({ idStore, type: 'PEDIDO_COPIA' });

            idStore = 'PEDIDO_PESQ_PRODUTO';
            dispatch({ idStore, type: 'CLEAN_PRODUTO' });  
            dispatch({ idStore, type: 'PRODUTOs', value: [] });  
      
            CarregarPedidoItens(notificacao, dispatch, response.data.data);    

            thenFunction()        

            //return false  
          }else{
            alertar(notificacao, 'br', "NÃO foi possível fazer a alteração.", 'warning', 'icon-alert-circle-exc', 2);
            return true  
          }
      }).catch(e => {
          alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
          return true
      });
  } catch (e) {
      alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
      return true
  }
}


/*--------------------------------------------------------------------------------------- 
 * Editar Item (Uma a Um)
 *---------------------------------------------------------------------------------------*/
export async function EditItem(notificacao, dispatch, item, thenFunction){
  try {

    let ulr = '';

    const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');

    ulr = api.urlBaseChefe + api.urlPedidoEditarItem; 

    //var invalido = false;
    if (item.quantidade === '') {
        //dispatch({ idStore, type: 'INVALIDO_ITEM', campo: 'quantidade', value: true })
        alertar(notificacao, 'br', "Informe a QUANTIDADE.", 'warning', 'icon-alert-circle-exc', 2);
        //invalido = true;
        return true;
    }

    if (item.valorItem === '') {
      //dispatch({ idStore, type: 'INVALIDO_ITEM', campo: 'valorItem', value: true })
      alertar(notificacao, 'br', "Informe o VALOR DO ITEM.", 'warning', 'icon-alert-circle-exc', 2);
      //invalido = true;
      return true;
    }

    /*-------------------------------------------------------------------- 
     * Montar Item para envio    
     *--------------------------------------------------------------------*/

    var valorTotalItem = 0;
    var valorItem = 0;
    valorItem = removeMaskMoney(item.valorItem)
    valorTotalItem = item.quantidade * valorItem;

    const itemDigitado = []
    itemDigitado.push({
      IdProduto: item.idProduto,
      idPedidoItem: item.idPedidoItem,
      quantidade: item.quantidade,
      valorItem: valorItem,
      valorTotal: valorTotalItem,
      observacaoItem: item.observacaoItem,
    });


    return await axios({
      method: 'POST',
      url: ulr,
      headers: {"Content-Type": "application/json", 'Authorization': bearerAuth,},
      data: {
        idPedido: item.idPedido,
        idEmpresa: item.idEmpresa,
        pedidoItensManutencaoList: itemDigitado,
      }
    }).then(response => {
        if (response.data.message === "Sucesso"){

          //Limpa Objsetos
          let idStore = 'PEDIDO';
          dispatch({ idStore, type: 'CLEAN_PEDIDO' });
          dispatch({ idStore, type: 'PEDIDOITENs', value: [] })
          dispatch({ idStore, type: 'PEDIDO_COPIA' })

          idStore = 'PEDIDO_PESQ_PRODUTO';
          dispatch({ idStore, type: 'CLEAN_PRODUTO' })  
          dispatch({ idStore, type: 'PRODUTOs', value: [] })  

          GetMesaComanda(notificacao, dispatch, item.idEmpresa, '', item.idPedido, () => {
            
          });

          thenFunction()        

          //return false  
        }else{
          alertar(notificacao, 'br', "NÃO foi possível fazer a alteração.", 'warning', 'icon-alert-circle-exc', 2);
          return true  
        }
    }).catch(e => {
        alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        return true
    });
} catch (e) {
    alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    return true
}
}


/*--------------------------------------------------------------------------------------- 
 * Remover Item
 *---------------------------------------------------------------------------------------*/
export async function RemoverItem(notificacao, dispatch, item, thenFunction){
  try {

    let ulr = '';

    const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');

    ulr = api.urlBaseChefe + api.urlPedidoEditarItem; 

    /*-------------------------------------------------------------------- 
     * Montar Item para envio    
     * Envia Quantidade ZERO para item ser REMOVIDO
     *--------------------------------------------------------------------*/
    var valorTotalItem = 0;
    var valorItem = 0;
    valorItem = removeMaskMoney(item.valorItem)
    valorTotalItem = item.quantidade * valorItem;

    const itemDigitado = []
    itemDigitado.push({
      IdProduto: item.idProduto,
      idPedidoItem: item.idPedidoItem,
      quantidade: '0',
      valorItem: valorItem,
      valorTotal: valorTotalItem,
      observacaoItem: item.observacaoItem,
    });


    return await axios({
      method: 'POST',
      url: ulr,
      headers: {"Content-Type": "application/json", 'Authorization': bearerAuth,},
      data: {
        idPedido: item.idPedido,
        idEmpresa: item.idEmpresa,
        pedidoItensManutencaoList: itemDigitado,
      }
    }).then(response => {
        if (response.data.message === "Sucesso"){

          //Limpa Objsetos
          let idStore = 'PEDIDO';
          dispatch({ idStore, type: 'CLEAN_PEDIDO' });
          dispatch({ idStore, type: 'PEDIDOITENs', value: [] })
          dispatch({ idStore, type: 'PEDIDO_COPIA' })

          idStore = 'PEDIDO_PESQ_PRODUTO';
          dispatch({ idStore, type: 'CLEAN_PRODUTO' })  
          dispatch({ idStore, type: 'PRODUTOs', value: [] })  

          GetMesaComanda(notificacao, dispatch, item.idEmpresa, '', item.idPedido, () => {
            
          });

          thenFunction()        

          //return false  
        }else{
          alertar(notificacao, 'br', "NÃO foi possível fazer a alteração.", 'warning', 'icon-alert-circle-exc', 2);
          return true  
        }
    }).catch(e => {
        alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        return true
    });
} catch (e) {
    alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    return true
}
}


/*--------------------------------------------------------------------------------------- 
 * Envia Conta para Impressão no chefeDesktop
 *---------------------------------------------------------------------------------------*/
export async function EnviarContaImpressao(notificacao, dispatch, pedido, thenFunction){
  try {

    let ulr = '';

    const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');

    ulr = api.urlBaseChefe + api.urlPedidoContaImpressao; 

    return await axios({
      method: 'POST',
      url: ulr,
      headers: {"Content-Type": "application/json", 'Authorization': bearerAuth,},
      data: {
        IdPedidoImpressao: 0,
        idPedido: pedido.idPedido,
        idEmpresa: pedido.idEmpresa,
        IdUsuario: pedido.idUsuario,
        UsuarioNome: pedido.nomeUsuarioVenda,
        ClienteNome: pedido.nomeCliente,
        Impresso: 'N',
      }
    }).then(response => {
        if (response.data.message === "Sucesso"){
          alertar(notificacao, 'br', "Conta Enviada para Impressão com SUCESSO.", 'success', 'icon-check-2', 2);
          thenFunction()        
        }else{
          alertar(notificacao, 'br', "NÃO foi possível enviar a conta para impressão.", 'warning', 'icon-alert-circle-exc', 2);
          return true  
        }
    }).catch(e => {
        alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        return true
    });
} catch (e) {
    alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    return true
}
}

/*--------------------------------------------------------------------------------------- 
 * Envia Produtos para Impressão no chefeDesktop
 * Envia somente os produtos que estiverem DESMARCADOS, indicando que precisam ser 
 * Impressos novamente
 *---------------------------------------------------------------------------------------*/
export async function EnviarItensProducao(notificacao, dispatch, pedidoItens, thenFunction){
  try {

    let ulr = '';

    const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');

    ulr = api.urlBaseChefe + api.urlPedidoItensImpressao; 

    const itensProducao = []
    var totalItens = pedidoItens.length;

    for (var i = 0; i < totalItens; i++) {
      if (pedidoItens[i].impresso === 'N' || pedidoItens[i].impresso === ''){
        itensProducao.push({
          IdPedidoItem: pedidoItens[i].idPedidoItem,
          IdPedido: pedidoItens[i].idPedido,
          IdEmpresa: pedidoItens[i].idEmpresa,
          IdProduto_Item: pedidoItens[i].idProduto,
          impresso: 'N',
          produzido: 'N',
          integrado: 'N',
        });    
      }
    }

    if (itensProducao.length === 0){
      alertar(notificacao, 'br', "Você precisa DESMARCAR os produtos que deseja imprimir.", 'info', 'icon-alert-circle-exc', 4);
      return true
    }

    return await axios({
      method: 'PUT',
      url: ulr,
      headers: {"Content-Type": "application/json", 'Authorization': bearerAuth,},
      data: itensProducao,
    }).then(response => {
        if (response.data.message === "Sucesso"){
          alertar(notificacao, 'br', "Produtos Enviados para Produção com SUCESSO.", 'success', 'icon-check-2', 2);
          thenFunction()        
        }else{
          alertar(notificacao, 'br', "NÃO foi possível enviar para impressão.", 'warning', 'icon-alert-circle-exc', 2);
          return true  
        }
    }).catch(e => {
        alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        return true
    });
} catch (e) {
    alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    return true
}
}

/*--------------------------------------------------------------------------------------- 
 * Trocar Mesa ou Comanda por outra, todos os itens do pedido vão juntos
 *---------------------------------------------------------------------------------------*/
export async function TrocarMesaComanda(notificacao, dispatch, pedidoTrocar, thenFunction){
  try {

    const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');

    let ulr = '';
    var tipoPedido = pedidoTrocar.tipoPedido;
    var numeroMesaComanda = adicionarZerosEsquerda(pedidoTrocar.mesaComandaNova, 3);    
    
    ulr = api.urlBaseChefe + api.urlPedidoTrocar + `${pedidoTrocar.idEmpresa}` + `/${pedidoTrocar.idPedido}` + `/${numeroMesaComanda}` + `/${tipoPedido}`;

    return await axios({
      method: 'POST',
      url: ulr,
      headers: {"Content-Type": "application/json", 'Authorization': bearerAuth,},
    }).then(response => {
        if (response.data.message === "Sucesso"){

          //Limpa Objsetos
          let idStore = 'PEDIDO';
          dispatch({ idStore, type: 'CLEAN_PEDIDO' });
          dispatch({ idStore, type: 'PEDIDOITENs', value: [] })
          dispatch({ idStore, type: 'PEDIDO_COPIA' })

          idStore = 'PEDIDO_PESQ_PRODUTO';
          dispatch({ idStore, type: 'CLEAN_PRODUTO' })  
          dispatch({ idStore, type: 'PRODUTOs', value: [] })  

          GetMesaComanda(notificacao, dispatch, pedidoTrocar.idEmpresa, pedidoTrocar.mesaComandaNova, '', () => {
            
          });

          thenFunction()        

        }else{
          alertar(notificacao, 'br', "NÃO foi possível fazer a troca.", 'warning', 'icon-alert-circle-exc', 2);
          return true  
        }
    }).catch(e => {
        alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        return true
    });
} catch (e) {
    alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    return true
}
}

/*--------------------------------------------------------------------------------------- 
 * Excluir Mesa/Comanda
 *---------------------------------------------------------------------------------------*/
export async function ExcluirReabrirMesaComanda(notificacao, dispatch, usuarioPedido, mensagem, acao, thenFunction){
  try {

    let ulr = '';

    const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');

    //E = Excluir
    //R = Reabrir
    if (acao === 'E'){
      ulr = api.urlBaseChefe + api.urlPedidoExcluir; 
    }else{
      ulr = api.urlBaseChefe + api.urlPedidoReabrir; 
    }
    

    usuarioPedido.pedidoAberto = 'N';
    usuarioPedido.status = '1';


    return await axios({
      method: 'POST',
      url: ulr,
      headers: {"Content-Type": "application/json", 'Authorization': bearerAuth,},
      data: {
        pedido: usuarioPedido,
      }
    }).then(response => {
        if (response.data.message === "Sucesso"){

          //Limpa Objsetos
          let idStore = 'PEDIDO';
          dispatch({ idStore, type: 'CLEAN_PEDIDO' });
          dispatch({ idStore, type: 'PEDIDOITENs', value: [] })
          dispatch({ idStore, type: 'PEDIDO_COPIA' })

          idStore = 'PEDIDO_PESQ_PRODUTO';
          dispatch({ idStore, type: 'CLEAN_PRODUTO' })  
          dispatch({ idStore, type: 'PRODUTOs', value: [] })  

          alertar(notificacao, 'br', "Pedido " + mensagem + " com SUCESSO.", 'success', 'icon-check-2', 2);
          
          if (acao === 'R'){
            GetMesaComanda(notificacao, dispatch, usuarioPedido.idEmpresa, '', usuarioPedido.idPedido, () => {
            
            });  
          }  

          thenFunction()        

          //return false  
        }else{
          alertar(notificacao, 'br', "Solicitação NÃO Confirmada.", 'warning', 'icon-alert-circle-exc', 2);
          return true  
        }
    }).catch(e => {
        alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        return true
    });
} catch (e) {
    alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    return true
}
}

/*--------------------------------------------------------------------------------------- 
 * Fechar Conta/Pedido
 *---------------------------------------------------------------------------------------*/
export async function FecharConta(notificacao, dispatch, pedidoPedidoFaturar, thenFunction){
  try {

    let ulr = '';

    const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');

    ulr = api.urlBaseChefe + api.urlPedidoFechar; 

    /*-------------------------------------------------------------------- 
     * Monta Pedido e Pagamento para envio    
     * Cria Lista de Pagamento
     * Cria Pagamento Cabeçalho
     * ValorTotal = valorSubTotal + valorTaxa + valorEntrega
     * Os outros valores serão gravados normal e para tela se faz as 
     * devidas subtrações ou acrescimos
     *--------------------------------------------------------------------*/

    const pagamento = []
    pagamento.push({
      idPagamento: 0,
      idEmpresa: pedidoPedidoFaturar.idEmpresa,
      idPedido: pedidoPedidoFaturar.idPedido,
      idForma: pedidoPedidoFaturar.formaPagamento,
      quantidadeParcela: 1,
      numeroParcela: 1,
      valorDesconto: removeMaskMoney(pedidoPedidoFaturar.valorDesconto),
      valorAcrescimo: removeMaskMoney(pedidoPedidoFaturar.valorAcrescimo),
      valorParcialTotal: removeMaskMoney(pedidoPedidoFaturar.valorParcial),
      valorParcela: removeMaskMoney(pedidoPedidoFaturar.valorTotal),
      valorTotal: removeMaskMoney(pedidoPedidoFaturar.valorTotal),
      dataInclusao: '',
      dataVencimento: '',
      status: '1',
    });

    const pedido = {
      idPedido: pedidoPedidoFaturar.idPedido,
      idEmpresa: pedidoPedidoFaturar.idEmpresa,
      idUsuario: pedidoPedidoFaturar.idUsuario,
      mesaComanda: pedidoPedidoFaturar.mesaComanda,
      enviarConta: '',
      pedidopagamento: pagamento,
    }

    return await axios({
      method: 'POST',
      url: ulr,
      headers: {"Content-Type": "application/json", 'Authorization': bearerAuth,},
      data: {
        pedido: pedido,
      }
    }).then(response => {
        if (response.data.message === "Sucesso"){
          //Limpa Objsetos
          let idStore = 'PEDIDO';
          dispatch({ idStore, type: 'CLEAN_PEDIDO' });
          dispatch({ idStore, type: 'PEDIDOITENs', value: [] })
          dispatch({ idStore, type: 'PEDIDO_COPIA' })
          dispatch({ idStore, type: 'CLEAN_PEDIDO_FATURAR' });

          idStore = 'PEDIDO_PESQ_PRODUTO';
          dispatch({ idStore, type: 'CLEAN_PRODUTO' })  
          dispatch({ idStore, type: 'PRODUTOs', value: [] })  

          alertar(notificacao, 'br', "Pedido FECHADO com SUCESSO.", 'success', 'icon-check-2', 3);

          thenFunction()        

          //return false  
        }else{
          alertar(notificacao, 'br', "NÃO foi possível fazer a alteração.", 'warning', 'icon-alert-circle-exc', 2);
          return true  
        }
    }).catch(e => {
        alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        return true
    });
} catch (e) {
    alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    return true
}
}

/*--------------------------------------------------------------------------------------- 
 * Pagamento Parcial
 *---------------------------------------------------------------------------------------*/
export async function IncluirPagamentoParcial(notificacao, dispatch, usuario, pedidoPagamentoParcial, thenFunction){
  try {

    let ulr = '';

    const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');

    ulr = api.urlBaseChefe + api.urlPedidoPagamentoParcial; 

    return await axios({
      method: 'POST',
      url: ulr,
      headers: {"Content-Type": "application/json", 'Authorization': bearerAuth,},
      data: {
        idPagamentoParcial: 0,
        idPedido: pedidoPagamentoParcial.idPedido,
        idUsuario: usuario.idUsuarioLogin,
        idForma: pedidoPagamentoParcial.idForma,
        valorPago: removeMaskMoney(pedidoPagamentoParcial.valorPago),
        nome: pedidoPagamentoParcial.nome,
        status: '1',
      }
    }).then(response => {
        if (response.data.message === "Sucesso"){
          //Limpa Objseto
          let idStore = 'PEDIDO';
          dispatch({ idStore, type: 'CLEAN_PEDIDO' });
          dispatch({ idStore, type: 'PEDIDOITENs', value: [] })
          dispatch({ idStore, type: 'PEDIDO_COPIA' })

          idStore = 'PEDIDO_PESQ_PRODUTO';
          dispatch({ idStore, type: 'CLEAN_PRODUTO' })  
          dispatch({ idStore, type: 'PRODUTOs', value: [] })  

          GetMesaComanda(notificacao, dispatch, usuario.empresa, '', pedidoPagamentoParcial.idPedido, () => {
            
          });

          
          dispatch({ idStore, type: 'CLEAN_PEDIDO_PAGAMENTO_PARCIAL' });
          dispatch({ idStore, type: "CLEAN_INVALIDO_PAGAMENTO_PARCIAL" });
          alertar(notificacao, 'br', "Pagamento Realizado com SUCESSO.", 'success', 'icon-check-2', 3);

          thenFunction()        

          //return false  
        }else{
          alertar(notificacao, 'br', "NÃO foi possível fazer a alteração.", 'warning', 'icon-alert-circle-exc', 2);
          return true  
        }
    }).catch(e => {
        alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        return true
    });
} catch (e) {
    alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    return true
}
}


/*--------------------------------------------------------------------------------------- 
 * BUSCAR INFORMAÇÕES DE PAGAMENTO DO PEDIDO 
 * idPedido = id do pedido
 *---------------------------------------------------------------------------------------*/
export async function GetPagamentoInformacao(notificacao, dispatch, idEmpresa, idPedido, thenFunction){
  try {
    
        var ulrPesquisa = '';

        ulrPesquisa = api.urlBaseChefe + api.urlPedidoContaItens + `${idEmpresa}` + `/${idPedido}`;

        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: ulrPesquisa,
        headers: { 
            'Authorization': bearerAuth
        }
        };
        
        axios.request(config)
        .then((response) => {
          if (response.data.message === 'Sucesso') {

            var pedidoPedido = response.data.data.pedido;
            var pedidoItens = response.data.data.pedidoItens;
            var pedidoPagamento = response.data.data.pedidoPagamento;
            var pedidoEnderecoEntrega = pedidoPedido.pedidoEnderecoEntrega;
            let type = 'PEDIDO_FATURAR';

            dispatch({ idStore, type: 'CLEAN_PEDIDO_FATURAR' });

            
              dispatch({ idStore, type, campo: 'idPedido', value: pedidoPedido.idPedido })
              dispatch({ idStore, type, campo: 'idEmpresa', value: pedidoPedido.idEmpresa })
              dispatch({ idStore, type, campo: 'mesaComanda', value: pedidoPedido.mesaComanda })
              dispatch({ idStore, type, campo: 'tipoVenda', value: pedidoPedido.tipoVenda })
              dispatch({ idStore, type, campo: 'percentualTaxa', value: pedidoPedido.percentualTaxa })
  
              dispatch({ idStore, type, campo: 'valorAcrescimo', value: maskMoeda(pedidoPedido.valorAcrescimo.toFixed(2)) })
              dispatch({ idStore, type, campo: 'valorDesconto', value: maskMoeda(pedidoPedido.valorDesconto.toFixed(2)) })
              dispatch({ idStore, type, campo: 'valorTaxa', value: maskMoeda(pedidoPedido.valorTaxa.toFixed(2)) })
              dispatch({ idStore, type, campo: 'valorEntrega', value: maskMoeda(pedidoPedido.valorEntrega.toFixed(2)) })
              dispatch({ idStore, type, campo: 'valorParcial', value: maskMoeda(pedidoPedido.valorParcialTotal.toFixed(2)) })   
              dispatch({ idStore, type, campo: 'pagamentosParcial', value: pedidoPedido.pagamentosParcial })   
              dispatch({ idStore, type, campo: 'pedidoItens', value: pedidoItens })   
              
              dispatch({ idStore, type, campo: 'valorSubTotal', value: maskMoeda(pedidoPedido.valorSubTotal.toFixed(2)) })
              dispatch({ idStore, type, campo: 'valorTotal', value: maskMoeda(pedidoPedido.valorTotal.toFixed(2)) })

              if (pedidoPagamento.length > 0){
                dispatch({ idStore, type, campo: 'formaPagamento', value: pedidoPagamento[0].idForma })
              }  
              
              dispatch({ idStore, type, campo: 'enderecoEntrega', value: pedidoEnderecoEntrega })      
  
              dispatch({ idStore, type, campo: 'valorRestante', value: '0,00' })
              dispatch({ idStore, type, campo: 'valorTroco', value: '0,00' })

              var valorPagoParcial = pedidoPedido.valorParcialTotal

              if (pedidoPedido.pedidoAberto === 'N'){
                dispatch({ idStore, type, campo: 'valorPagoDigitado', value: maskMoeda(pedidoPedido.valorTotal.toFixed(2)) })  
              }else if (valorPagoParcial > 0){
                dispatch({ idStore, type, campo: 'valorPagoDigitado', value: maskMoeda(pedidoPedido.valorParcialTotal.toFixed(2)) })  
              }else{
                dispatch({ idStore, type, campo: 'valorPagoDigitado', value: '0,00' })  
              }

            thenFunction()
              
          }else{
            alertar(notificacao, 'br', "Nenhum Pedido encontrado", 'info', 'icon-bulb-63', 2)
          }
        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
    }

}


/*--------------------------------------------------------------------------------------- 
 * Excluir Pagamento Parcial
 *---------------------------------------------------------------------------------------*/
export async function ExcluirPagamentoParcial(notificacao, dispatch, pagamentoParcial, thenFunction){
  try {

    let ulr = '';

    const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');

    ulr = api.urlBaseChefe + api.urlPedidoExcluirPagamentoParcial + `${pagamentoParcial.idPagamentoParcial}`; 

    return await axios({
      method: 'POST',
      url: ulr,
      headers: {"Content-Type": "application/json", 'Authorization': bearerAuth,},
    }).then(response => {
        if (response.data.message === "Sucesso"){

          //Limpa Objsetos
          let idStore = 'PEDIDO';
          dispatch({ idStore, type: 'CLEAN_PEDIDO' });
          dispatch({ idStore, type: 'PEDIDOITENs', value: [] })
          dispatch({ idStore, type: 'PEDIDO_COPIA' })

          idStore = 'PEDIDO_PESQ_PRODUTO';
          dispatch({ idStore, type: 'CLEAN_PRODUTO' })  
          dispatch({ idStore, type: 'PRODUTOs', value: [] })  

          alertar(notificacao, 'br', "Pagamento EXCLUÍDO com SUCESSO.", 'success', 'icon-check-2', 3);

          GetMesaComanda(notificacao, dispatch, pagamentoParcial.idEmpresa, '', pagamentoParcial.idPedido, () => {
            
          });

          thenFunction()        

          //return false  
        }else{
          alertar(notificacao, 'br', "NÃO foi possível fazer a alteração.", 'warning', 'icon-alert-circle-exc', 2);
          return true  
        }
    }).catch(e => {
        alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
        return true
    });
} catch (e) {
    alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
    return true
}
}



/*--------------------------------------------------------------------------------------- 
 * CARREGA INFORMAÇÕES DO PRODUTO QUE FOI PESQUISADO
 *---------------------------------------------------------------------------------------*/
export function CarregarProduto(notificacao, dispatch, produto, row, thenFunction){
  try {

    let idStore = 'PEDIDO_PESQ_PRODUTO'
    let type = 'PRODUTO';
    dispatch({ idStore, type, campo: 'idProduto', value: produto[row].idProduto })
    dispatch({ idStore, type, campo: 'idEmpresa', value: produto[row].idEmpresa })
    dispatch({ idStore, type, campo: 'idGrupo', value: produto[row].idGrupo })
    dispatch({ idStore, type, campo: 'descricaoProduto', value: produto[row].descricao })
    dispatch({ idStore, type, campo: 'descricaoProdutoDetalhe', value: produto[row].descricaoDetalhada })
    dispatch({ idStore, type, campo: 'codigoProduto', value: produto[row].codigoProduto })
    if (produto[row].imagemProduto != null){
      dispatch({ idStore, type, campo: 'imagemProduto', value: produto[row].imagemProduto })
    }else{
      dispatch({ idStore, type, campo: 'imagemProduto', value: "" })
    }
    dispatch({ idStore, type, campo: 'valorProduto', value: maskMoeda(produto[row].valorProduto.toFixed(2)) })
    dispatch({ idStore, type, campo: 'impressaoProduto', value: produto[row].impressaoProduto })
    dispatch({ idStore, type, campo: 'tempoPreparo', value: produto[row].tempoPreparo })
    dispatch({ idStore, type, campo: 'tipoProduto', value: produto[row].tipoProduto })
    dispatch({ idStore, type, campo: 'statusProduto', value: produto[row].status })

    dispatch({ idStore, type, campo: 'idProdutoSelecionado', value: produto[row].idProduto })
    dispatch({ idStore, type, campo: 'quantidade', value: '1' })
    dispatch({ idStore, type, campo: 'observacao', value: '' })  
    
  } catch (error) {
    alertar(notificacao, 'br', "Não foi possível pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
  }
}


export function CarregarProdutoPesquisa(notificacao, dispatch, produto, row, thenFunction){
  try {

    let idStore = 'PEDIDO_PESQ_PRODUTO'
    let type = 'PESQUISA';
    dispatch({ idStore, type, campo: 'idProduto', value: produto[row].idProduto })
    dispatch({ idStore, type, campo: 'idEmpresa', value: produto[row].idEmpresa })
    dispatch({ idStore, type, campo: 'descricaoProduto', value: produto[row].descricao })
    dispatch({ idStore, type, campo: 'codigoProduto', value: produto[row].codigoProduto })
        
  } catch (error) {
    alertar(notificacao, 'br', "Não foi possível pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
  }
}


/*--------------------------------------------------------------------------------------- 
  * Retorna Pedidos por data
  * ID_EMPRESA = NUMERO DA EMPRESA
  * DATA INICIO YYYY-MM-DD
  * DATA FINAL YYYY-MM-DD
  * FORMA DE PAGAMENTO = CASO PASSE ZERO NAO FILTRA PELA FORMA (1,2,3,4)
  * PEDIDO ABERTO = S, N OU T (TODOS)
 *---------------------------------------------------------------------------------------*/
export async function GetPedidosDataForma(notificacao, dispatch, idEmpresa, idCaixa, pesquisa, thenFunction){
  try {
    
        var ulrPesquisa = '';
        var dataPesquisa = '';
        var formaPagamento = '';

        let idStore = 'CAIXA'
        let type = 'CAIXA_VENDAS_SOMA';
        dispatch({ idStore, type, campo: 'valorSubTotal', value: '0,00' })
        dispatch({ idStore, type, campo: 'valorTaxas', value: '0,00' })
        dispatch({ idStore, type, campo: 'valorEntrega', value: '0,00' })
        dispatch({ idStore, type, campo: 'valorTotal', value: '0,00' })

        if (idCaixa === ''){
          return;
        }

        //dataPesquisa = formatarData('EN', pesquisa.dataPesquisa);
        dataPesquisa = pesquisa.dataPesquisa;

        if (pesquisa.formaPagamento === 'T'){
          formaPagamento = '0'
        }else{
          formaPagamento = pesquisa.formaPagamento;
        }

        ulrPesquisa = api.urlBaseChefe + api.urlPedidoConsultaDataForma + `${idEmpresa}` + `/${dataPesquisa}` + `/${" "}` + `/${formaPagamento}` + `/${"N"}` + `/${idCaixa}`;

        const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
        let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: ulrPesquisa,
        headers: { 
            'Authorization': bearerAuth
        }
        };
        
        axios.request(config)
        .then((response) => {
          if (response.data.message === 'Sucesso') {
            
            var vendas = response.data.data;
            let idStore = 'PEDIDO'
            dispatch({ idStore, type: 'CAIXA_VENDAS', value: [] }); 

            if (vendas.length !== 0){
              dispatch({ idStore, type: 'CAIXA_VENDAS', value: vendas });

              var valorSubTotal = 0;
              var valorTaxas = 0;
              var valorEntrega = 0;
              var valorTotal = 0;
        
              for (var i = 0; i < vendas.length; i++) {
                valorSubTotal += vendas[i].valorSubTotal;
                valorTaxas += vendas[i].valorTaxaServico;
                valorEntrega += vendas[i].valorEntrega;
                valorTotal += vendas[i].valorTotal;
              }

              valorSubTotal = valorSubTotal.toFixed(2);
              valorTaxas = valorTaxas.toFixed(2);
              valorEntrega = valorEntrega.toFixed(2);
              valorTotal = valorTotal.toFixed(2);

              idStore = 'CAIXA'
              let type = 'CAIXA_VENDAS_SOMA';      
              dispatch({ idStore, type, campo: 'valorSubTotal', value: maskMoeda(valorSubTotal)})
              dispatch({ idStore, type, campo: 'valorTaxas', value: maskMoeda(valorTaxas)})
              dispatch({ idStore, type, campo: 'valorEntrega', value: maskMoeda(valorEntrega)})
              dispatch({ idStore, type, campo: 'valorTotal', value: maskMoeda(valorTotal)})
            }

            thenFunction()
              
          }else{
            alertar(notificacao, 'br', "Nenhum Pedido encontrado", 'info', 'icon-bulb-63', 2)
          }
        })
    } catch (e) {
        alertar(notificacao, 'br', "Não foi possível pesquisar, tente mais tarde2.", 'warning', 'icon-alert-circle-exc', 2)
    }

}

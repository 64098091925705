import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import ReactTable from "react-table-6";
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { ModalBasic, ModalBasicBody, ModalBasicFooter } from "~/components/ModalBasic/ModalBasic";
import * as controllerPedidoVendas from "~/controller/Vendas/controllerPedidoVendas"
import { Select } from "~/components/ErnComponets/ErnComponets";
import '~/views/Vendas/Pedido/Modals/styles.css'
import { maskMoeda,removeMaskMoney } from "~/Util/mascaras";

import {
    Input,
    Row,
    Col,
    Button,
} from "reactstrap";


const ModalRemoverItem = ({isOpen, toggleModalRemoverItem, pedidoPedidoItem, shouldClean}) => {

    const login = useSelector((state) => state.login);
    const notificacao = useSelector((state) => state.notificacao);
    const [loading, setLoading] = useState(false);

    const [itemRemover, setitemRemover] = useState({idPedido: '', idEmpresa: '', idPedidoItem: '', idProduto: '', quantidade: '', valorItem: '', desconto: '', valorTotal: '', observacaoItem: '' })
    const dispatch = useDispatch();

      useEffect(() => {
      if (pedidoPedidoItem !== null){
        setitemRemover({ ...itemRemover, idPedido: pedidoPedidoItem.idPedido,
                                         idEmpresa: pedidoPedidoItem.idEmpresa,
                                         idPedidoItem: pedidoPedidoItem.idPedidoItem,
                                         idProduto: pedidoPedidoItem.idProduto,
                                         quantidade: pedidoPedidoItem.quantidade,
                                         valorItem: pedidoPedidoItem.valorItem,
                                         desconto: pedidoPedidoItem.desconto,
                                         valorTotal: pedidoPedidoItem.valorTotal,
                                         observacaoItem: pedidoPedidoItem.observacaoItem                                           
         })
      }
    }, [])
 
    function handleVoltar(){
      let idStore = 'PEDIDO_ITEM'
      dispatch({ idStore, type: "CLEAN_PEDIDO_ITEM" });

      toggleModalRemoverItem();
    }

    async function handleExcluir(){
      setLoading(true);
      if (!loading) {
        await controllerPedidoVendas.RemoverItem(notificacao, dispatch, itemRemover, () => {

        });  
      }
      setLoading(false);

      let idStore = 'PEDIDO_ITEM'
      dispatch({ idStore, type: "CLEAN_PEDIDO_ITEM" });
      toggleModalRemoverItem();
    }

    function handleKeyPress(event, value) {
        if (event.key === 'Enter') {
            //handlePesquisar();
        }
    }

    return (
          <ModalBasic
            id="modalItem"
            isOpen={isOpen}
            toggle={() => toggleModalRemoverItem()}
            title={'Excluir Item'}
            size="md" // sm, md, lg, xl = TAMANHO da Modal
          > 
            <ModalBasicBody>
                <Row className="padding-padrao-row">
                  <Col className="padding-padrao-col">
                  <label>Produto</label>
                      <Input
                          style={{textTransform:"uppercase" , fontSize: '16px' }}
                          id='produtoDescricao'
                          type='text'
                          autoComplete='off'
                          value={pedidoPedidoItem.produtoDescricao}
                          disabled
                      />
                  </Col>
                </Row>      
            </ModalBasicBody>

            <ModalBasicFooter>
                <Row className="justify-content-center">
                    <Col lg md sm>
                        <Col className="text-center">
                            <Button
                                className='btn btn-green'
                                //color="info"
                                type="button"
                                onClick={handleVoltar}
                                style={{ width: '100px' }}
                            >Voltar
                            </Button>
                            <Button
                                color="warning"
                                type="button"
                                onClick={() => handleExcluir()}
                                style={{ width: '100px' }}
                            >Excluir
                            </Button>
                        </Col>
                    </Col>
                </Row>
            </ModalBasicFooter>
          
        </ModalBasic>
      
    )
}

export default ModalRemoverItem
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import * as controllerCliente from "~/controller/Cadastros/controllerCliente"
import ArrowTooltip from '~/components/Tooltip/ArrowTooltip'; 
import { Switch } from "~/components/ErnComponets/ErnComponets";
import ReactTable from "react-table-6";
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

import {
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Button,
  FormGroup,
  CustomInput,
  UncontrolledTooltip
} from "reactstrap";

const idStore = "CLIENTE";

const EntradaCadastro = () => {
  const notificacao = useSelector(state => state.notificacao);
  const login = useSelector((state) => state.login);
  const { ENDERECOs, cadastroCliente, cadastroClienteEndereco, ClienteCopia, invalido, invalidoEndereco } = useSelector((state) => state.Cliente);
  const dispatch = useDispatch();
  const [isEnabled, setIsEnabled] = useState(false);

  const [loading, setLoading] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [table, setTable] = useState(renderTable([]));
  const [tableVisible, setTableVisible] = useState(false);

  //Carregar uma vez
   useEffect(() => {
    handleStatus(true);
   }, [])
 
  function handleOnChange(value, campo) {
    dispatch({ idStore, type: "CLIENTE", campo: "idEmpresa", value: login.usuario.empresa }); 
    dispatch({ idStore, type: "CLIENTE", campo, value });
    dispatch({ idStore, type: "CLIENTE_ENDERECO", campo, value });
    //dispatch({ idStore, type: "INVALIDO", campo, value });
    //dispatch({ idStore, type: "INVALIDO_ENDERECO", campo, value });
  }

/*   async function handleEditarCadastro(obj) {
    setLoading(true);
    if (!loading) {

        await controllerCliente.GetClienteEndereco(notificacao, dispatch, obj.idCliente, obj.idEndereco, () => {
            dispatch({ idStore, type: 'CLEAN_INVALIDO_ENDERECO' });
        });
        setLoading(false);
    }
  } */



  function handleKeyPress(event, type) {
    if (event.key === "Enter") {
      switch (type) {
        case "cep":
          controllerCliente.GetCep(notificacao, dispatch, cadastroClienteEndereco.idEndereco, cadastroCliente.idCliente, cadastroClienteEndereco.cep);
          document.getElementById('numero').focus()
          break;
        default:
      }
    }
  }
 


  function handleStatus(campo){
    var statusCliente = '';
    if (isEnabled){
      statusCliente = '9';
      setIsEnabled(false);
    }else{
      statusCliente = '1';
      setIsEnabled(true);
    }
    
    dispatch({ idStore, type: 'CLIENTE', campo: campo, value: statusCliente })
  }

  // function SetImgPerfil(value) {
  //   dispatch({ idStore, type: 'USUARIO_LOGIN', campo: "fotoUsuario", value: value });
  // };

  async function Salvar(e) {
    e.preventDefault();
    if (cadastroCliente.idCliente === '') {
      controllerCliente.CreateCliente(notificacao, dispatch, cadastroCliente, cadastroClienteEndereco, 'I', ClienteCopia);
    } else {
      controllerCliente.CreateCliente(notificacao, dispatch, cadastroCliente, cadastroClienteEndereco, 'A', ClienteCopia);
    }
  };

  function Limpar(e) {
    e.preventDefault();
    dispatch({ idStore, type: 'CLEAN_CLIENTE' });
    dispatch({ idStore, type: 'CLEAN_INVALIDO' });
    dispatch({ idStore, type: 'CLEAN_CLIENTE_ENDERECO' });
    dispatch({ idStore, type: 'CLEAN_ENDERECOs' });
    dispatch({ idStore, type: 'CLEAN_INVALIDO_ENDERECO' });
    document.getElementById('celularCliente').focus()
  };

  //**** ENDEREÇOS ****/

  function LimparEndereco(e) {
    e.preventDefault();
    dispatch({ idStore, type: 'CLEAN_CLIENTE_ENDERECO' });
    dispatch({ idStore, type: 'CLEAN_INVALIDO_ENDERECO' });
    document.getElementById('cep').focus()
  };


  async function handleEditarCadastro(obj) {
    setLoading(true);
    if (!loading) {
        await controllerCliente.GetClienteEndereco(notificacao, dispatch, obj.idCliente, obj.idEndereco, () => {
            dispatch({ idStore, type: 'CLEAN_INVALIDO_ENDERECO' });
        });
        setLoading(false);
    }
  }

  async function handleAtivarInativarCadastro(obj) {
    setLoading(true);
    if (!loading) {
      var statusNovo = '1';
      if (obj.statusEndereco === 'ATIVO'){
        statusNovo = '9'
      }
      await controllerCliente.AtivarInativarEndereco(notificacao, dispatch, obj.idEndereco, statusNovo, () => {
        controllerCliente.PesquisarEndereco(notificacao, dispatch, obj.idCliente);
    });
    setLoading(false);

    }
  }


  useEffect(() => {
    setTable(renderTable());
  }, [dataTable, loading]);

  function renderTable() {
    return dataTable.map((obj, key) => {
      return {
        ...dataTable[key],
        actions: (
          <div className="actions-center">
            <Button
              className="btn-link btn-icon"
              color="info"
              id="tooltipEditar"
              size="sm"
              title=""
              type="button"
              onClick={() => {
                handleEditarCadastro(obj);
              }}
            >
              <UncontrolledTooltip
                delay={0}
                target="tooltipEditar"
              >
                Editar Endereço
              </UncontrolledTooltip>                              
              <i className="tim-icons icon-pencil" />
            </Button>
            {obj.statusEndereco === 'ATIVO' ?
              <Button
                className="btn-link btn-icon"
                // color={obj.statusUsuario === 'ATIVO' ? "danger" : "success" }
                color="danger"
                id="tooltipInativar"
                size="sm"
                title=""
                type="button"
                onClick={() => {
                  handleAtivarInativarCadastro(obj);
                }}
              >              
                <UncontrolledTooltip
                  delay={0}
                  target="tooltipInativar"
                  >
                  Inativar Endereço
                </UncontrolledTooltip>                              
                <i className="tim-icons  icon-simple-remove"/>
              </Button>
            :
              <Button
                className="btn-link btn-icon"
                color="success"
                id="tooltipAtivar"
                size="sm"
                title=""
                type="button"
                onClick={() => {
                  handleAtivarInativarCadastro(obj);
                }}
              >
                  <UncontrolledTooltip
                    delay={0}
                    target="tooltipAtivar"
                    >
                    Ativar Endereço
                  </UncontrolledTooltip>                              
                {/* <i className={obj.statusUsuario === 'ATIVO' ? "tim-icons  icon-simple-remove" : "tim-icons icon-check-2"} /> */}
                <i className="tim-icons icon-check-2"/>
              </Button>
            }       
          </div>
        ),
      };
    });
  }


  //Monta Dados para Gride
  function tableData(data) {
    return data.map((prop, key) => {
      return {
        id: key,
        idEndereco: prop["idEndereco"],
        idCliente: prop["idCliente"],
        cep: prop["cep"],
        endereco: prop["endereco"] + " - " + prop["numero"],
        bairro: prop["bairro"],
        cidade: prop["cidade"] + " - " + prop["uf"],
        complemento: prop["complemento"],
        statusEndereco: prop["status"],
      };
    });
  }

  //Endereço - Se existir registro carrega e deixa a gride visivel
  useEffect(() => {
    setDataTable(tableData(ENDERECOs));
    if (ENDERECOs.length > 0) {
      setTableVisible(true);
    } else {
      setTableVisible(false);
    }
  }, [ENDERECOs]);



  return (
    <>
      <Row className="justify-content-center" style={{ marginTop: "-80px" }}>
        <Col>
            <Row className="justify-content-center" >
              <Col md="12">
                <fieldset className="fieldset-resultados" style={{ height: '100%', marginTop: '10px' }}>
                  <legend>Informações da Entrada</legend>
                  <Row style={{ marginLeft: "25px", marginRight: "25px"}}>

                    <Col className="padding-padrao-col" lg="1" md="2">
                      <label>Id Entrada</label>
                        <Input
                          id="idCliente"
                          type="text"
                          disabled
                          value={cadastroCliente.idCliente}
                          onChange={(e) =>
                            handleOnChange(e.target.value, e.target.id)
                          }
                          autoComplete="off"
                        />
                    </Col>

                    <Col className="padding-padrao-col" lg="2" md="3">
                      <label>Num. Documento</label>
                      <FormGroup className={invalido.celular ? "has-danger" : ''}>
                        <Input
                          id="celularCliente"
                          type="text"
                          value={cadastroCliente.celularCliente}
                          onChange={(e) =>
                            handleOnChange(e.target.value, e.target.id)
                          }
                          autoComplete="off"
                          maxLength="30"
                        />
                      </FormGroup>
                    </Col>

                    <Col md="2" className="padding-padrao-col">
                    <label>Data Documento</label>
                    <Input
                        style={{textTransform:"uppercase" , fontSize: '14px' }}
                        id='dataDocumento'
                        type='date'
                        autoComplete='off'
                        //value={pesquisa.dataPesquisa}
                        onChange={(e) => handleOnChange(e.target.value, e.target.id)}
                        maxLength="15"
                      />
                  </Col>

                  <Col md="2" className="padding-padrao-col">
                    <label>Data Entrada</label>
                    <Input
                        style={{textTransform:"uppercase" , fontSize: '14px' }}
                        id='dataEntrada'
                        type='date'
                        autoComplete='off'
                        //value={pesquisa.dataPesquisa}
                        onChange={(e) => handleOnChange(e.target.value, e.target.id)}
                        maxLength="15"
                      />
                  </Col>


{/*                   <Col className="padding-padrao-col" lg="2" md="2">
                    <label>Operação</label>
                     <Select
                      id="operacaoSelect"
                      select={{ value: "idOperacao", label: "descricao" }}
                      first={"Selecione"}
                      value={{ value: operacao.idOperacao }}
                      options={operacoes}
                      isSearchable={true}
                      onChange={(value) =>
                        onChangeUsuario(value.value)
                      }  
                      autoComplete="off"
                    />
                   </Col> */}
                    <Col className="padding-padrao-col" lg="4" md="3">
                      <label>Operação</label>
                      <FormGroup className={invalido.nome ? "has-danger" : ''}>
                        <Input
                          disabled
                          id="nomeCliente"
                          type="text"
                          value={cadastroCliente.nomeCliente}
                          //onChange={e => onChange(e.target.value, 'nome')}
                          onChange={(e) =>
                            handleOnChange(e.target.value, e.target.id)
                          }    
                          autoComplete="off"
                          maxLength="80"
                        />
                      </FormGroup>
                    </Col>  
                  </Row>
                  <Row style={{ marginLeft: "25px", marginRight: "25px"}}>
                    <Col className="padding-padrao-col" lg="1" md="3">
                        <label>Fornecedor</label>
                        <FormGroup className={invalido.nome ? "has-danger" : ''}>
                          <Input
                            disabled
                            id="idFornecedor"
                            type="text"
                            value={cadastroCliente.nomeCliente}
                            onChange={(e) =>
                              handleOnChange(e.target.value, e.target.id)
                            }    
                            autoComplete="off"
                            maxLength="15"
                          />
                        </FormGroup>
                    </Col>
                    <Col className="padding-padrao-col" lg="4" md="3">
                        <label>Nome/Razão Social</label>
                        <FormGroup className={invalido.nome ? "has-danger" : ''}>
                          <Input
                            disabled
                            id="nomeFornecedor"
                            type="text"
                            value={cadastroCliente.nomeCliente}
                            onChange={(e) =>
                              handleOnChange(e.target.value, e.target.id)
                            }    
                            autoComplete="off"
                            maxLength="50"
                          />
                        </FormGroup>
                    </Col>
                    <Col className="padding-padrao-col" lg="2" md="3">
                        <label>CPF/CNPJ</label>
                        <FormGroup className={invalido.nome ? "has-danger" : ''}>
                          <Input
                            disabled
                            id="cpfCnpj"
                            type="text"
                            value={cadastroCliente.nomeCliente}
                            onChange={(e) =>
                              handleOnChange(e.target.value, e.target.id)
                            }    
                            autoComplete="off"
                            maxLength="15"
                          />
                        </FormGroup>
                    </Col>
                    <Col lg="auto" className="padding-padrao-col">
                      <Button
                          style={{ marginLeft: '0px', marginTop: '26px', width: '40px', marginBottom: '0px' }}
                          type='button'
                          color="info"
                          //onClick={() => handlePesquisar()}
                      >
                          <Icon icon="search" className="fa-lg" />
                      </Button>
                    </Col>
                  </Row>
                </fieldset>  
              </Col>
            </Row>
        </Col>
      </Row>

      <fieldset className="fieldset-resultados" style={{ height: '100%', marginTop: '10px' }}>
        <legend>Produto</legend>
          <Row style={{ marginLeft: "25px", marginRight: "25px"}}>

            <Col className="padding-padrao-col" lg="2" md="2">
              <label>Código Produto</label>
                <Input
                  id="idEndereco"
                  type="text"
                  value={cadastroClienteEndereco.idEndereco}
                  onChange={(e) =>
                    handleOnChange(e.target.value, e.target.id)
                  }
                  autoComplete="off"
                />
            </Col>
            <Col lg="auto" className="padding-padrao-col">
              <Button
                  style={{ marginLeft: '0px', marginTop: '26px', width: '40px', marginBottom: '0px' }}
                  type='button'
                  color="info"
                  //onClick={() => handlePesquisar()}
              >
                  <Icon icon="search" className="fa-lg" />
              </Button>
            </Col>


            <Col className="padding-padrao-col" lg="6" md="3">
              <label>Descrição</label>
              <FormGroup className={invalidoEndereco.cep ? "has-danger" : ''}>
                <Input
                  disabled
                  id="cep"
                  type="text"
                  value={cadastroClienteEndereco.cep}
                  onKeyPress={(e) => handleKeyPress(e, "cep")}
                  onChange={(e) =>
                    handleOnChange(e.target.value, e.target.id)
                  }
                  autoComplete="off"
                  maxLength="10"
                />
              </FormGroup>
            </Col>

            <Col className="padding-padrao-col" lg="1" md="3">
              <label>Quantidade</label>
              <FormGroup className={invalidoEndereco.endereco ? "has-danger" : ''}>
                <Input
                  id="endereco"
                  type="text"
                  value={cadastroClienteEndereco.endereco}
                  //onChange={e => onChange(e.target.value, 'nome')}
                  onChange={(e) =>
                    handleOnChange(e.target.value, e.target.id)
                  }    
                  autoComplete="off"
                  maxLength="150"
                />
              </FormGroup>
            </Col>
            <Col className="padding-padrao-col" lg="1" md="3">
              <label>Valor Unitário</label>
              <FormGroup className={invalidoEndereco.endereco ? "has-danger" : ''}>
                <Input
                  id="endereco"
                  type="text"
                  value={cadastroClienteEndereco.endereco}
                  //onChange={e => onChange(e.target.value, 'nome')}
                  onChange={(e) =>
                    handleOnChange(e.target.value, e.target.id)
                  }    
                  autoComplete="off"
                  maxLength="150"
                />
              </FormGroup>
            </Col>

            <Col className="padding-padrao-col" lg="1" md="3" >
              <label>Total</label>
              <FormGroup className={invalidoEndereco.endereco ? "has-danger" : ''}>
                <Input
                  style={{ marginLeft: '5px', fontWeight: 'bold', fontSize: '22px' }}
                  disabled
                  id="endereco"
                  type="text"
                  value={cadastroClienteEndereco.endereco}
                  //onChange={e => onChange(e.target.value, 'nome')}
                  onChange={(e) =>
                    handleOnChange(e.target.value, e.target.id)
                  }    
                  autoComplete="off"
                  maxLength="150"
                />
              </FormGroup>
            </Col>
            <Col lg="auto" className="padding-padrao-col">
              <ArrowTooltip title='Adicionar Item'>    
                <Button
                    className='btn btn-green'
                    style={{ marginLeft: '3px', marginTop: '26px', width: '40px', marginBottom: '0px', fontWeight: 'bold', fontSize: '22px' }}
                    type='button'
                    //color="info"
                    //onClick={() => handlePesquisar()}
                >
                  +
                  <Icon icon="icon-simple-add" className="fa-lg" />
                </Button>
                </ArrowTooltip>  
            </Col>
          </Row>

        </fieldset>

        <fieldset className="fieldset-resultados" style={{ height: '100%', marginTop: '10px' }}>
          <legend>Produtos da Entrada</legend>
          <Row style={{ marginLeft: "25px", marginRight: "25px"}}>
            <Col>
              <div
                  style={
                  tableVisible
                    ? {
                        transition: "0.5s ease-in-out",
                        visibility: "visible",
                        opacity: "1",
                      }
                    : { visibility: "hidden", opacity: "0" }
                  }
                >
              {/* INCIO TabPesquisa-GRADE */}
              <ReactTable
                data={table}
                filterable={false}
                columns={[
                  {
                    Header: "id Endereco",
                    accessor: "idEndereco",
                  },
                  {
                    Header: "Cep",
                    accessor: "cep",
                  },
                  {
                    Header: "Endereço",
                    accessor: "endereco",
                  },
                  {
                    Header: "Bairro",
                    accessor: "bairro",
                  },
                  {
                    Header: "Cidade",
                    accessor: "cidade",
                  },
                  {
                    Header: "Complemento",
                    accessor: "complemento",
                  },
                  {
                    Header: "Status",
                    accessor: "statusEndereco",
                  },
                  {
                    Header: "",
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                minRows={1}
                showPaginationTop={false}
                showPaginationBottom={false}
                className="-striped -highlight"
                noDataText=""
                previousText="Anterior"
                nextText="Próximo"
                loadingText="Carregando"
                pageText="Página"
                ofText="de"
                rowsText="linhas"
              />
              </div>
            </Col>                  
          </Row>
        </fieldset>
                      

      <Row className="justify-content-center" style={{ marginTop: "0px" }}>
        <Col>
          <CardFooter className="text-center">
            <Row className="justify-content-center">
              <Col md="10" >
                <Col className="text-center">
                <ArrowTooltip title='Limpar'>
                  <Button 
                    style={{ width: '120px'}}
                    //className="btn-padrao" 
                    color="default" 
                    onClick={(e) => Limpar(e)}
                    >
                      <Icon icon="icon-trash-simple" className="fa-lg" color="#FFF" pull="left" />{" "}
                      Limpar
                  </Button>
                </ArrowTooltip>
                <ArrowTooltip title='Importar XML de Nota Fiscal'>
                  <Button 
                  //className="btn-padrao" 
                    style={{ width: '150px'}}
                    color="Primary" 
                    onClick={(e) => Salvar(e)}>
                      <Icon icon="download" className="fa-lg" color="#FFF" pull="left" />{" "}
                      Immporta XML
                  </Button>
                </ArrowTooltip>
                <ArrowTooltip title='Salva e Continuar Depois'>
                  <Button 
                    //className="btn-padrao" 
                    style={{ width: '120px'}}
                    color="info" 
                    onClick={(e) => Salvar(e)}>
                      <Icon icon="save" className="fa-lg" color="#FFF" pull="left" />{" "}
                      Salvar
                      </Button>
                    </ArrowTooltip>
                <ArrowTooltip title='Finalizar Entrada'>
                  <Button 
                  //className="btn btn-green" 
                    style={{ width: '120px'}}
                    color="info" 
                    onClick={(e) => Salvar(e)}>
                      <Icon icon="dollar-sign" className="fa-lg" color="#FFF" pull="left" />{" "}
                      Finalizar
                    </Button>
                  </ArrowTooltip>
                </Col>
              </Col>
            </Row>
          </CardFooter>
        </Col>
      </Row>






    </>
  )
};

//export default UsuarioCadastro;

 class Wizard extends React.Component {
  focusInput = () => {
    setTimeout(() => {
      document.getElementById('celularCliente').focus()
    }, 100)
  }

  render() {
    return (
      <EntradaCadastro />
    )
  }
}

export default Wizard;

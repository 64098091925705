import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import * as controllerCaixa from "~/controller/Financeiro/controllerCaixa"
import ArrowTooltip from '~/components/Tooltip/ArrowTooltip'; 
import * as controllerPedidoVendas from '~/controller/Vendas/controllerPedidoVendas';
import { maskMoeda } from "~/Util/mascaras";
import ReactTable from "react-table-6";

import {
  Input,
  Row,
  Col,
  CardBody,
  Button,
  CardFooter,
  FormGroup,
  CardTitle,
  CustomInput,
  UncontrolledTooltip,
} from "reactstrap";

import useModal from '~/Util/Hooks/useModal.ts';
import ModalPedido from "~/views/Financeiro/FecharCaixa/Modals/modalPedido.jsx";

const idStore = "CAIXA";


const CaixaVendas = () => {
  const notificacao = useSelector(state => state.notificacao);
  const login = useSelector((state) => state.login);
  const {  CAIXAVENDAs, pedidoPedidoFaturar } = useSelector((state) => state.PedidoItem);
  const {  caixaVendasSoma } = useSelector((state) => state.Caixa);

  const dispatch = useDispatch();

  const [dataTable, setDataTable] = useState([]);
  const [table, setTable] = useState(renderTable([]));
  const [tableVisible, setTableVisible] = useState(false);
  const [width, setWidht] = useState(window.innerWidth);
  const [loading, setLoading] = useState(false);

  const [shouldClean, setshouldClean] = useState(false)
  const [modalPedido, toggleModalPedido] = useModal(false)


  useEffect(() => {
    setTable(renderTable());
  }, [dataTable, loading]);


   function renderTable() {
    return dataTable.map((obj, key) => {
      return {
        ...dataTable[key],
        actions: (
          <div className="actions-center">
            <Button
              className="btn-link btn-icon"
              color="info"
              id="tooltipVisualizar"
              size="sm"
              title=""
              type="button"
              onClick={() => {
                handleVisualizar(obj);
              }}
            >
              <UncontrolledTooltip
                delay={0}
                target="tooltipVisualizar"
              >
                Visualizar Informações
              </UncontrolledTooltip>                              
              <i className="tim-icons icon-notes" />
            </Button>
          </div>
        ),
        mesaComanda:(
          <div style={{textAlign: 'left', fontSize: '12px' }}>
              {obj.mesaComanda}
          </div>          
        ),
        dataPedido:(
          <div style={{textAlign: 'left', fontSize: '12px' }}>
              {obj.dataPedido}
          </div>          
        ),
        dataFechamento:(
          <div style={{textAlign: 'left', fontSize: '12px' }}>
              {obj.dataFechamento}
          </div>          
        ),
        tempoNaMesa:(
          <div style={{textAlign: 'left', fontSize: '12px' }}>
              {obj.tempoNaMesa}
          </div>          
        ),

        descricaoForma:(
          <div style={{textAlign: 'left', fontSize: '12px' }}>
              {obj.descricaoForma}
          </div>          
        ),
        subTotal:(
          <div style={{textAlign: 'right', fontSize: '12px' }}>
              {obj.valorSubTotal}
          </div>          
        ),
        valorTaxa:(
          <div style={{textAlign: 'right', fontSize: '12px' }}>
              {obj.valorTaxaServico}
          </div>          
        ),
        valorEntrega:(
          <div style={{textAlign: 'right', fontSize: '12px' }}>
              {obj.valorEntrega}
          </div>          
        ),
        valorTotal:(
          <div style={{textAlign: 'right', fontSize: '14px' }}>
              {obj.valorTotal}
          </div>          
        ),
        nomeCliente:(
          <div style={{textAlign: 'left', fontSize: '12px' }}>
              {obj.nomeCliente}
          </div>          
        ),
      };
    });
  }

  //Monta Dados para Gride
  function tableData(data) {
    return data.map((prop, key) => {
      return {
        id: key,
        idEmpresa: prop["idEmpresa"],
        idPedido: prop["idPedido"],
        mesaComanda: prop["mesaComanda"],
        dataPedido: prop["dataPedido"],
        dataFechamento: prop["dataFechamento"],
        tempoNaMesa: prop["tempoNaMesa"],
        descricaoForma: prop["descricaoForma"],
        valorSubTotal: maskMoeda(prop["valorSubTotal"].toFixed(2)),
        valorTaxaServico: maskMoeda(prop["valorTaxaServico"].toFixed(2)),
        valorEntrega: maskMoeda(prop["valorEntrega"].toFixed(2)),
        valorTotal: maskMoeda(prop["valorTotal"].toFixed(2)),
        nomeCliente: prop["nomeCliente"],
      };
    });
  }

  //Se existir registro carrega e deixa a gride visivel
  useEffect(() => {
    setDataTable(tableData(CAIXAVENDAs));
    if (CAIXAVENDAs.length > 0) {
      setTableVisible(true);
    } else {
      setTableVisible(false);
    }
  }, [CAIXAVENDAs]);


  //Informações do Pedido
  async function handleVisualizar(obj) {
    setLoading(true);
    if (!loading) {
      await controllerPedidoVendas.GetPagamentoInformacao(notificacao, dispatch, obj.idEmpresa, obj.idPedido, () => {
        toggleModalPedido();    
      });
    }
    setLoading(false);
  }


  function Limpar(e) {
    e.preventDefault();
    dispatch({ idStore, type: 'CLEAN_CAIXA' });
    dispatch({ idStore, type: 'CLEAN_INVALIDO' });
    document.getElementById('valorAbertura').focus()
  };

  
  return (
    <>
      <Row className="justify-content-center" style={{ marginTop: "-120px" }}>
        <Col>
          <CardBody>


            <Row className="justify-content-height">
              <Col className="padding-padrao-col" md="8">
              </Col>
              <Col className="padding-padrao-col" md="4">
                <fieldset className="fieldset-resultados" style={{ height: '100%' }}>
                  <legend>Totais</legend>
                  <Row className="padding-padrao-row">
                    <Col className="padding-padrao-col" lg="5" md="1" style={{ marginTop: '0px' }}>
                      <Input
                        id="labelSubTotal"
                        type="text"
                        autoComplete="off"
                        disabled
                        style={{ fontSize: '18px', textAlign: 'left', backgroundColor: '#969696' }}
                        value="Sub Total R$"
                      />
                    </Col>

                    <Col className="padding-padrao-col" lg="7" md="2" style={{ marginTop: '0px' }}>
                      <Input
                        disabled
                        tabIndex={6}
                        style={{textAlign: 'right', fontSize: '22px', color: '#fff', backgroundColor: '#646464' }}
                        borderTopRightRadius="0"
                        id="valorSubTotal"
                        type="text"
                        autoComplete="off"
                        value={caixaVendasSoma.valorSubTotal}
                      />
                    </Col>          
                  </Row>

                  <Row className="padding-padrao-row">
                    <Col className="padding-padrao-col" lg="5" md="1" style={{ marginTop: '0px' }}>
                      <Input
                        id="labelValorTaxa"
                        type="text"
                        autoComplete="off"
                        disabled
                        style={{ fontSize: '18px', textAlign: 'left', backgroundColor: '#969696' }}
                        value="Taxas R$"
                      />
                    </Col>

                    <Col className="padding-padrao-col" lg="7" md="2" style={{ marginTop: '0px' }}>
                      <Input
                        disabled
                        tabIndex={6}
                        style={{textAlign: 'right', fontSize: '22px', color: '#d88e04', backgroundColor: '#646464' }}
                        borderTopRightRadius="0"
                        id="valorTotalTaxaServico"
                        type="text"
                        autoComplete="off"
                        value={caixaVendasSoma.valorTaxas}
                        maxLength="15"
                      />
                    </Col>
                              
                  </Row>
                  <Row className="padding-padrao-row">
                    <Col className="padding-padrao-col" lg="5" md="1" style={{ marginTop: '0px' }}>
                      <Input
                        id="labelValorEntrega"
                        type="text"
                        autoComplete="off"
                        disabled
                        style={{ fontSize: '18px', textAlign: 'left', backgroundColor: '#969696' }}
                        value="Entrega R$"
                      />
                    </Col>

                    <Col className="padding-padrao-col" lg="7" md="2" style={{ marginTop: '0px' }}>
                      <Input
                        disabled
                        tabIndex={6}
                        style={{textAlign: 'right', fontSize: '22px', color: '#d88e04', backgroundColor: '#646464' }}
                        borderTopRightRadius="0"
                        id="valorTotalEntrega"
                        type="text"
                        autoComplete="off"
                        value={caixaVendasSoma.valorEntrega}
                        maxLength="15"
                      />
                    </Col>
                              
                  </Row>
                  <Row className="padding-padrao-row">
                    <Col className="padding-padrao-col" lg="5" md="1" style={{ marginTop: '0px' }}>
                      <Input
                        id="labelTotal"
                        type="text"
                        autoComplete="off"
                        disabled
                        style={{ fontSize: '18px', textAlign: 'left', backgroundColor: '#969696' }}
                        value="Total R$"
                      />
                    </Col>

                    <Col className="padding-padrao-col" lg="7" md="2" style={{ marginTop: '0px' }}>
                      <Input
                        disabled
                        tabIndex={6}
                        style={{textAlign: 'right', fontSize: '26px', fontWeight: 'bold', color: '#0077ff', backgroundColor: '#646464' }}
                        borderTopRightRadius="0"
                        id="valorTotal"
                        type="text"
                        autoComplete="off"
                        value={caixaVendasSoma.valorTotal}
                        maxLength="15"
                      />
                    </Col>
                              
                  </Row>
                  

                </fieldset>  
              </Col>
            </Row>
            <br></br>
              {CAIXAVENDAs.length === 0 ? 
                <div style={{textAlign: 'center', borderRadius: '5px', alignContent: 'center', height: '50px', backgroundColor: '#e2e2e2' }}>
                  <label style={{textAlign: 'center', fontSize: '22px', color: '#797979' }}>Sem Pedidos</label>
                </div>        
              :
                <div
                  style={
                    tableVisible
                      ? {
                          transition: "0.5s ease-in-out",
                          visibility: "visible",
                          opacity: "1",
                        }
                      : { visibility: "hidden", opacity: "0" }
                  }
                >
                  <ReactTable
                    data={table}
                    filterable={false}
                    columns={[
                      {
                        Header: "Mesa/Comanda",
                        accessor: "mesaComanda",
                        minWidth: 40,
                        //resizable: false,
                        sortable: false
                      },
                      {
                        Header: "Entrada",
                        accessor: "dataPedido",
                        minWidth: 50,
                        //resizable: false,
                        sortable: false
                      },
                      {
                        Header: "Saída",
                        accessor: "dataFechamento",
                        minWidth: 50,
                        //resizable: false,
                        sortable: false
                      },
                      {
                        Header: "Permanecia",
                        accessor: "tempoNaMesa",
                        minWidth: 30,
                        //resizable: false,
                        sortable: false
                      },
                      {
                        Header: "Pagamento",
                        accessor: "descricaoForma",
                        minWidth: 50,
                        //resizable: false,
                        sortable: false
                      },
                      {
                        Header: () => (<div style={{ textAlign: 'right', fontSize: '12px'}}>SUB TOTAL</div>),
                        accessor: "subTotal",
                        minWidth: 40,
                        //resizable: false,
                        sortable: false
                      },
                      {
                        Header: () => (<div style={{ textAlign: 'right', fontSize: '12px'}}>TAXA</div>),
                        accessor: "valorTaxa",
                        minWidth: 30,
                        //resizable: false,
                        sortable: false
                      },
                      {
                        Header: () => (<div style={{ textAlign: 'right', fontSize: '12px' }}>ENTREGA</div>),
                        accessor: "valorEntrega",
                        minWidth: 30,
                        //resizable: false,
                        sortable: false
                      },
                      {
                        Header: () => (<div style={{ textAlign: 'right', fontSize: '12px'}}>TOTAL</div>),
                        accessor: "valorTotal",
                        minWidth: 40,
                        //resizable: false,
                        sortable: false
                      },
                      {
                        Header: "Cliente",
                        accessor: "nomeCliente",
                        minWidth: 40,
                        //resizable: false,
                        sortable: false

                      },
                    {
                        Header: "",
                        accessor: "actions",
                        minWidth: 20,
                        //resizable: false,
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    minRows={1}
                    showPaginationTop={false}
                    showPaginationBottom={true}
                    className="-striped -highlight"
                    noDataText=""
                    previousText="Anterior"
                    nextText="Próximo"
                    loadingText="Carregando"
                    pageText="Página"
                    ofText="de"
                    rowsText="linhas"
                  />
                </div>                
              }
          </CardBody>
          <CardFooter className="text-center">
            <Row className="justify-content-center">
              <Col md="10" >
                <Col className="text-center">
                <ArrowTooltip title='Voltar'><Button className="btn-padrao" color="default" onClick={(e) => Limpar(e)}>Voltar</Button></ArrowTooltip>
                {/* <ArrowTooltip title='shift+S'><Button className="btn-padrao" color="info" onClick={(e) => Salvar(e)}>Salvar</Button></ArrowTooltip> */}
                </Col>
              </Col>
            </Row>
          </CardFooter>
        </Col>
      </Row>


      {modalPedido ?
          <ModalPedido
            isOpen={true}
            toggleModalPedido={toggleModalPedido}
            pedidoPedidoFaturar={pedidoPedidoFaturar}
            shouldClean={shouldClean}
        />
        : null}



    </>
  )
};

//export default UsuarioCadastro;

 class Wizard extends React.Component {
  focusInput = () => {
    setTimeout(() => {
      //document.getElementById('codigoProduto').focus()
    }, 100)
  }

  render() {
    return (
      <CaixaVendas />
    )
  }
}

export default Wizard;

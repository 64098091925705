import axios from "axios"
import api from "~/Util/api.js";
import { alertar } from "~/Util/alertar";
import {removeMaskMoney} from "~/Util/mascaras";


const idStore = 'CONFIGURACAO';

/*-------------------------------------------------------------------------------------
* Utilizado para INCLUIR ou ALTERAR 
* OPERACAO = 'I' = INCLUIR / 'A' = ALTERACAO
*-------------------------------------------------------------------------------------*/
export async function CreateUpdateConfiguracao(notificacao, dispatch, cadastroConfiguracao, operacao, cadastroConfiguracaoCopia) {
  try {
  
      let ulr = '';
      const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
  
      ulr = api.urlBaseChefe + api.urlCadastroConfiguracaoIncluirAlterar;
  
  
      if (JSON.stringify(cadastroConfiguracao) === JSON.stringify(cadastroConfiguracaoCopia)) {
        alertar(notificacao, 'br', "Nenhuma Alteraçõe foi Feita no Cadastro.", 'info', 'icon-bulb-63', 2);
        return false
      }        
  
  
      if (operacao === 'I'){
        cadastroConfiguracao.idConfiguracao = "0";
      }
  
      var invalido = false;
      if (cadastroConfiguracao.quantidadeMesas === '') {
          dispatch({ idStore, type: 'INVALIDO', campo: 'quantidadeMesas', value: true })
          invalido = true
      }
      if (cadastroConfiguracao.percentualTaxa === '') {
        cadastroConfiguracao.percentualTaxa = '0';
      }
      if (cadastroConfiguracao.valorEntrega === '') {
        cadastroConfiguracao.valorEntrega = '0';
      }
      if (cadastroConfiguracao.horaInicioTrabalho === '') {
        cadastroConfiguracao.horaInicioTrabalho = '0';
      }
      if (cadastroConfiguracao.horaFinalTrabalho === '') {
        cadastroConfiguracao.horaFinalTrabalho = '0';
      }
      if (cadastroConfiguracao.mensagemRecibo === '') {
        dispatch({ idStore, type: 'INVALIDO', campo: 'mensagemRecibo', value: true })
        invalido = true
      }
  
      if (invalido) {
          alertar(notificacao, 'br', "Campo Inválido", 'warning', 'icon-alert-circle-exc', 2);
          return true
      }

      var mesasComandasSequencia = '';
      if (cadastroConfiguracao.utilizaMesa === 'S'){
        mesasComandasSequencia = 'M';
      }
      if (cadastroConfiguracao.utilizaComanda === 'S'){
        mesasComandasSequencia = mesasComandasSequencia + 'C';
      }

      if (cadastroConfiguracao.utilizaPedidoSequencial === 'S'){
        mesasComandasSequencia = mesasComandasSequencia + 'S';
      }

      cadastroConfiguracao.mesasComandas = mesasComandasSequencia;

      return await axios({
        method: 'POST',
        url: ulr,
        headers: {"Content-Type": "application/json", 'Authorization': bearerAuth,},
        data: {
            IdConfiguracao: cadastroConfiguracao.idConfiguracao,
            IdEmpresa: cadastroConfiguracao.idEmpresa,
            QuantidadeMesas: cadastroConfiguracao.quantidadeMesas,
            PercentualTaxa: removeMaskMoney(cadastroConfiguracao.percentualTaxa),
            ValorEntrega: removeMaskMoney(cadastroConfiguracao.valorEntrega),
            HoraInicioTrabalho: cadastroConfiguracao.horaInicioTrabalho,
            HoraFinalTrabalho: cadastroConfiguracao.horaFinalTrabalho,
            MensagemRecibo: cadastroConfiguracao.mensagemRecibo,
            MesasComandas: cadastroConfiguracao.mesasComandas,        
            VincularMesaComandas: cadastroConfiguracao.vincularMesaComandas,        
            CobrarTaxaServicoEntrega: cadastroConfiguracao.cobrarTaxaServicoEntrega,            

            Logo: cadastroConfiguracao.logo,
            TamanhoFonte: '',
            DDDTelefone: '',
        }
      }).then(response => {
          if (response.data.message === 'Sucesso'){
            alertar(notificacao, 'br', "Ação Realizada com SUCESSO.", 'success', 'icon-check-2', 2)
          }
  
          //dispatch({ idStore, type: 'PRODUTO', campo: 'idProduto', value: user.idUsuarioLogin })
          //dispatch({ idStore, type: 'PRODUTO_COPIA' })
  
          return false
      }).catch(e => {
          alertar(notificacao, 'br', e.response ? e.response.data.message : 'Falha na conexão', 'warning', 'icon-alert-circle-exc', 2, e)
          return true
      });
  } catch (e) {
      alertar(notificacao, 'br', "Não foi possível concluir", 'warning', 'icon-alert-circle-exc', 2)
      return true
  }
  }

  
  export async function GetConfiguracao(notificacao, dispatch, idEmpresa, thenFunction){
    try {
      
          var ulrPesquisa = '';
          ulrPesquisa = api.urlBaseChefe + api.urlCadastroConfiguracaoGetConfiguracao + `${idEmpresa}`;
  
          const bearerAuth = 'Bearer ' + localStorage.getItem('TOKEN_KEY');
          let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: ulrPesquisa,
          headers: { 
              'Authorization': bearerAuth
          }
          };
          
          axios.request(config)
          .then((response) => {
            if (response.data.message === 'Sucesso') {
              let type = 'CONFIGURACAO';
              dispatch({ idStore, type, campo: 'idConfiguracao', value: response.data.data.idConfiguracao })
              dispatch({ idStore, type, campo: 'idEmpresa', value: response.data.data.idEmpresa })
              dispatch({ idStore, type, campo: 'quantidadeMesas', value: response.data.data.quantidadeMesas })
              dispatch({ idStore, type, campo: 'percentualTaxa', value: response.data.data.percentualTaxa })
              dispatch({ idStore, type, campo: 'valorEntrega', value: response.data.data.valorEntrega })
              dispatch({ idStore, type, campo: 'cobrarTaxaServicoEntrega', value: response.data.data.cobrarTaxaServicoEntrega })
              dispatch({ idStore, type, campo: 'vincularMesaComandas', value: response.data.data.vincularMesaComandas })
              dispatch({ idStore, type, campo: 'mensagemRecibo', value: response.data.data.mensagemRecibo })
              dispatch({ idStore, type, campo: 'mesasComandas', value: response.data.data.mesasComandas })
              dispatch({ idStore, type, campo: 'logo', value: response.data.data.logo })
              dispatch({ idStore, type, campo: 'horaInicioTrabalho', value: response.data.data.horaInicioTrabalho })
              dispatch({ idStore, type, campo: 'horaFinalTrabalho', value: response.data.data.horaFinalTrabalho })

              if (response.data.data.mesasComandas === 'MCS'){
                dispatch({ idStore, type, campo: 'utilizaMesa', value: 'S' })
                dispatch({ idStore, type, campo: 'utilizaComanda', value: 'S' })
                dispatch({ idStore, type, campo: 'utilizaPedidoSequencial', value: 'S' })
              }  
              if (response.data.data.mesasComandas === 'M'){
                dispatch({ idStore, type, campo: 'utilizaMesa', value: 'S' })
                dispatch({ idStore, type, campo: 'utilizaComanda', value: 'N' })
                dispatch({ idStore, type, campo: 'utilizaPedidoSequencial', value: 'N' })
              }  
              if (response.data.data.mesasComandas === 'C'){
                dispatch({ idStore, type, campo: 'utilizaMesa', value: 'N' })
                dispatch({ idStore, type, campo: 'utilizaComanda', value: 'S' })
                dispatch({ idStore, type, campo: 'utilizaPedidoSequencial', value: 'N' })
              }  
              if (response.data.data.mesasComandas === 'S'){
                dispatch({ idStore, type, campo: 'utilizaMesa', value: 'N' })
                dispatch({ idStore, type, campo: 'utilizaComanda', value: 'N' })
                dispatch({ idStore, type, campo: 'utilizaPedidoSequencial', value: 'S' })
              }  
              if (response.data.data.mesasComandas === 'MC'){
                dispatch({ idStore, type, campo: 'utilizaMesa', value: 'S' })
                dispatch({ idStore, type, campo: 'utilizaComanda', value: 'S' })
                dispatch({ idStore, type, campo: 'utilizaPedidoSequencial', value: 'N' })
              }  
              if (response.data.data.mesasComandas === 'MS'){
                dispatch({ idStore, type, campo: 'utilizaMesa', value: 'S' })
                dispatch({ idStore, type, campo: 'utilizaComanda', value: 'N' })
                dispatch({ idStore, type, campo: 'utilizaPedidoSequencial', value: 'S' })
              }  
              if (response.data.data.mesasComandas === 'CS'){
                dispatch({ idStore, type, campo: 'utilizaMesa', value: 'N' })
                dispatch({ idStore, type, campo: 'utilizaComanda', value: 'S' })
                dispatch({ idStore, type, campo: 'utilizaPedidoSequencial', value: 'S' })
              }  

      
              dispatch({ idStore, type: 'CONFIGURACAO_COPIA' })
              thenFunction()
      
                
            }else{
              alertar(notificacao, 'br', "Configuração não encontrada", 'info', 'icon-bulb-63', 2)
            }
          })
      } catch (e) {
          alertar(notificacao, 'br', "Não foi possível pesquisar, tente mais tarde.", 'warning', 'icon-alert-circle-exc', 2)
      }
  
  }
  
import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import ReactTable from "react-table-6";
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { ModalBasic, ModalBasicBody, ModalBasicFooter } from "~/components/ModalBasic/ModalBasic";
import * as controllerPedidoVendas from "~/controller/Vendas/controllerPedidoVendas"
import * as controllerCliente from "~/controller/Cadastros/controllerCliente"
import { Select } from "~/components/ErnComponets/ErnComponets";
import '~/views/Vendas/Pedido/Modals/styles.css'

import {
    Input,
    Row,
    Col,
    Button,
} from "reactstrap";

const ModalEndrecos = ({isOpen, toggleModalEndereco, pedidoCampos, shouldClean}) => {
    //const { PEDIDOs } = useSelector((state) => state.PedidoItem, shallowEqual);

    const { ENDERECOs } = useSelector((state) => state.Cliente, shallowEqual);
    const { PEDIDOENDERECOs, pesquisaPedidoEndereco } = useSelector((state) => state.PedidoItem, shallowEqual);
    const [pesquisaEndereco, setpesquisaEndereco] = useState({linhaSelecionada: '', idEndereco: ''})
  
    const login = useSelector((state) => state.login);
    const notificacao = useSelector((state) => state.notificacao);
    const [loading, setLoading] = useState(false);

    const [pesquisa, setpesquisa] = useState({ celular: '', nomeCliente: '' })
    const [enderecosSelected, setenderecosSelected] = useState({ idEndereco: 0 })
    const [enderecos, setEnderecos] = useState([])
    const dispatch = useDispatch();

    useEffect(() => {
      
      let idStore = 'PEDIDO'
      dispatch({ idStore, type: 'PEDIDOENDERECOs', value: [] })  

      dispatch({ idStore, type: "PESQUISA_PEDIDO_ENDERECO", campo: 'idCliente', value: '' });
      dispatch({ idStore, type: "PESQUISA_PEDIDO_ENDERECO", campo: 'celular', value: pedidoCampos.mesaComanda });
      dispatch({ idStore, type: "PESQUISA_PEDIDO_ENDERECO", campo: 'nomeCliente', value: '' });

      //setpesquisa({ ...pesquisa, celular: pedidoCampos.mesaComanda, nomeCliente: '' })

      handlePesquisar(pedidoCampos.mesaComanda);

    }, [])
    
    async function handlePesquisar(celular){
      setLoading(true);
      if (!loading) {
        await controllerCliente.GetClienteEnderecoCelular(notificacao, dispatch, celular, () => {
            // if (PEDIDOENDERECOs.length > 0){
            //   setpesquisa({ ...pesquisa, celular: celular, nomeCliente: PEDIDOENDERECOs[0].nomeCliente })
            //   document.getElementById("nomeCliente").value = PEDIDOENDERECOs[0].nomeCliente;
            //   document.getElementById("celular").value = celular;  
            // }
        });
        //await controllerPedidoVendas.GetMesasComandasDelivery(notificacao, dispatch, login.usuario, pesquisa, () => {
        //    document.getElementById("nomeCliente").focus();  
        //});
        setLoading(false);
      }
    }


    //  useEffect(() => {
    //    if (pedidoCampos !== ''){
    //      setpesquisa({ ...pesquisa, celular: pedidoCampos.mesaComanda })
    //      handlePesquisar(pesquisa.celular);
    //    }
    //   }, [pedidoCampos])

    useEffect(() => {
        setEnderecos(validaList(PEDIDOENDERECOs))
    }, [PEDIDOENDERECOs])

    useEffect(() => {
        if (shouldClean) {
          let idStore = 'PEDIDO'
          dispatch({ idStore, type: 'PEDIDOENDERECOs', value: [] })  

          //setpesquisa({ ...pesquisa, celular: '' })
        }
    }, [shouldClean])

    function handleOnChange(value, campo) {
      let idStore = 'PEDIDO'
      switch (campo) {
        case "celular":
          dispatch({ idStore, type: "PESQUISA_PEDIDO_ENDERECO", campo: 'celular', value: value });          
          //setpesquisa({ ...pesquisa, celular: value })
          //pedidoCampos.mesaComanda = value;
          break;
        // case "nomeCliente":
        //   dispatch({ idStore, type: "PESQUISA_PEDIDO_ENDERECO", campo: 'nomeCliente', value: value });
        //   //setpesquisa({ ...pesquisa, nomeCliente: value })                    
        //   break;
        default:
          break;
      }  
    }

    function handleVoltar(){
      toggleModalEndereco();
    }

    function handleOk(){
      //Endreço Selecionado - Armazenar para enviar no pedido
      let enderecoSelected = PEDIDOENDERECOs[pesquisaEndereco.linhaSelecionada]

      let idStore = 'PEDIDO'
      dispatch({ idStore, type: "PEDIDO_ENDERECO_ENTREGA", campo: 'idEndereco', value: enderecoSelected.idEndereco });   
      dispatch({ idStore, type: "PEDIDO_ENDERECO_ENTREGA", campo: 'idCliente', value: enderecoSelected.idCliente });   
      dispatch({ idStore, type: "PEDIDO_ENDERECO_ENTREGA", campo: 'nomeEntrega', value: enderecoSelected.nomeCliente });   
      dispatch({ idStore, type: "PEDIDO_ENDERECO_ENTREGA", campo: 'cep', value: enderecoSelected.cep });   
      dispatch({ idStore, type: "PEDIDO_ENDERECO_ENTREGA", campo: 'endereco', value: enderecoSelected.endereco });   
      dispatch({ idStore, type: "PEDIDO_ENDERECO_ENTREGA", campo: 'bairro', value: enderecoSelected.bairro });   
      dispatch({ idStore, type: "PEDIDO_ENDERECO_ENTREGA", campo: 'cidade', value: enderecoSelected.cidade });   
      dispatch({ idStore, type: "PEDIDO_ENDERECO_ENTREGA", campo: 'uf', value: enderecoSelected.uf });   
      dispatch({ idStore, type: "PEDIDO_ENDERECO_ENTREGA", campo: 'numero', value: enderecoSelected.numero });
      dispatch({ idStore, type: "PEDIDO_ENDERECO_ENTREGA", campo: 'complemento', value: enderecoSelected.complemento });   

      //Armazena Informações do Cliente
      dispatch({ idStore, type:'PEDIDO', campo: 'nomeCliente', value: enderecoSelected.nomeCliente });
      dispatch({ idStore, type:'PEDIDO', campo: 'idCliente', value: enderecoSelected.idCliente });
      dispatch({ idStore, type:'PEDIDO', campo: 'tipoVenda', value: 'E' });

      toggleModalEndereco();
    }

  function onClick(rowIndex) {
    let enderecoSelected = PEDIDOENDERECOs[rowIndex]

    setpesquisaEndereco({linhaSelecionada: rowIndex, idEndereco: enderecoSelected.idEndereco })
    setpesquisa({ ...pesquisa, idEndereco: enderecoSelected.idEndereco })
  }

  function onDoubleClick(rowIndex) {
    let enderecoSelected = PEDIDOENDERECOs[rowIndex]
    setpesquisaEndereco({linhaSelecionada: rowIndex, idEndereco: enderecoSelected.idEndereco })

    handleOk();
  }

  function getTrProps(state, row, column) {
      return {
          style: {
              height: '50px',
              paddingTop: '0px',
              paddingBottom: '5px',
              backgroundColor: PEDIDOENDERECOs[row.index].idEndereco === pesquisaEndereco.idEndereco ? 'rgba(248, 170, 0, 0.74)' : ''
          },
          onClick: (e, handleOriginal) => {
              onClick(row.index)
              if (handleOriginal) {
                  handleOriginal()
              }
          },
          onDoubleClick: (e, handleOriginal) => {
              onDoubleClick(row.index)
              if (handleOriginal) {
                  handleOriginal()
              }
          }
      }
  }

  function getTdProps(state, rowInfo, column) {
    if (column.id === 'actions') {
        return { style: { padding: 0 } }
    } else {
        return {}
    }
  } 

    function handleKeyPress(event, value) {
        if (event.key === 'Enter') {
            handlePesquisar(pesquisa.celular);
        }
    }

    function handleLimpar() {
      let idStore = 'PEDIDO'
      dispatch({ idStore, type: 'PEDIDOENDERECOs', value: [] })  

      dispatch({ idStore, type: "PESQUISA_PEDIDO_ENDERECO", campo: 'idCliente', value: '' });
      dispatch({ idStore, type: "PESQUISA_PEDIDO_ENDERECO", campo: 'celular', value: '' });
      dispatch({ idStore, type: "PESQUISA_PEDIDO_ENDERECO", campo: 'nomeCliente', value: '' });

        //let idStore = 'PEDIDO';     
        //dispatch({ idStore, type: 'PEDIDOENDERECOs', value: [] })         

        //setpesquisa({ ...pesquisa, celular: '', nomeCliente: '' })
      setenderecosSelected({ ...enderecosSelected, idEndereco: '' })
    }

    function validaList(list) {
        let arr = []

        list.forEach((value) => {
            if (!arr.find(item => value.idEndereco === item.idEndereco)) {
                arr.push(value)                
            }
        })

        return arr
    }

    return (
          <ModalBasic
            id="modalEnderecos"
            isOpen={isOpen}
            toggle={() => toggleModalEndereco()}
            title={'Endereços do Cliente'}
            size="lg" // sm, md, lg, xl = TAMANHO da Modal
          > 
            <ModalBasicBody>
                <Row className="padding-padrao-row">

                  <Col md="3" className="padding-padrao-col">
                      <label>Número Celular</label>
                      <Input
                          style={{textTransform:"uppercase" , fontSize: '16px' }}
                          id='celular'
                          type='text'
                          autoComplete='off'
                          value={pesquisaPedidoEndereco.celular}
                          onChange={(e) => handleOnChange(e.target.value, e.target.id)}
                          onFocus={e => e.target.select()}
                          onKeyPress={(e) => {
                            if (e.target.value !== ''){
                              handleKeyPress(e, e.target.value);
                              if (e.key === 'Enter') e.target.select()  
                            }
                          }}
                          maxLength="15"
                      />
                  </Col>

                  <Col className="padding-padrao-col">
                      <label>Cliente</label>
                      <Input
                          disabled
                          style={{textTransform:"uppercase" , fontSize: '16px' }}
                          id='nomeCliente'
                          type='text'
                          autoComplete='off'
                          value={pesquisaPedidoEndereco.nomeCliente}
/*                           onChange={(e) => handleOnChange(e.target.value, e.target.id)}
                          onFocus={e => e.target.select()}
                          onKeyPress={(e) => {
                            if (e.target.value !== ''){
                              handleKeyPress(e, e.target.value);
                              if (e.key === 'Enter') e.target.select()  
                            }
                          }} */

                          maxLength={'30'}
                      />
                  </Col>

                  <Col lg="auto" className="padding-padrao-col">
                      <Button
                          style={{ marginLeft: '0px', marginTop: '34px', width: '40px', marginBottom: '0px' }}
                          type='button'
                          color="info"
                          onClick={() => handlePesquisar(pesquisa.celular)}
                      >
                          <Icon icon="search" className="fa-lg" />
                      </Button>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <fieldset className="fieldset-resultados">
                      <legend>Resultados</legend>
                       <div
                        className='scrollBar'
                        //ref={tabelaPedidos}
                        //ref={tableRef}
                        style={PEDIDOENDERECOs.length !== 0 ?
                            { transition: '0.5s ease-in-out', visibility: 'visible', opacity: '1', position: 'relative', maxHeight: 250 } :
                            { visibility: 'hidden', opacity: '0' }} >
                                
                        <ReactTable
                            data={PEDIDOENDERECOs}
                            NoDataComponent={() => (<></>)}
                            columns={[
                                {
                                    Header: 'id',
                                    accessor: 'idEndereco',
                                    minWidth: 20,
                                    resizable: false,
                                    sortable: false
                                },
                                {
                                    Header: 'CEP',
                                    accessor: 'cep',
                                    minWidth: 20,
                                    resizable: false,
                                    sortable: false
                                },
                                {
                                  Header: 'Endereço',
                                  accessor: 'endereco',
                                  minWidth: 40,
                                  resizable: false,
                                  sortable: false
                                },
                                {
                                  Header: 'Bairro',
                                  accessor: 'bairro',
                                  minWidth: 40,
                                  resizable: false,
                                  sortable: false
                                },
                                {
                                  Header: 'Cidade',
                                  accessor: 'cidade',
                                  minWidth: 30,
                                  resizable: false,
                                  sortable: false
                                },
                              ]}
                                
                            getTrProps={getTrProps}
                            getTdProps={getTdProps}
                            showPaginationTop={false}
                            showPaginationBottom={false}
                            className="-striped -highlight"
                            noDataText=""
                            pageSize={PEDIDOENDERECOs.length}
                        />
                      </div>
                    </fieldset>
                  </Col>
                </Row>

                <Col className="text-right">
                  <div>
                      {`${PEDIDOENDERECOs.length} Enereços`}
                  </div>
                </Col>
            </ModalBasicBody>

            <ModalBasicFooter>
                <Row className="justify-content-center">
                    <Col lg md sm>
                        <Col className="text-center">
                            <Button
                                className='btn btn-green'
                                //color="info"
                                type="button"
                                onClick={handleVoltar}
                                style={{ width: '100px' }}
                            >Voltar
                            </Button>
                            <Button
                                color="info"
                                type="button"
                                onClick={handleLimpar}
                                style={{ width: '100px' }}
                            >Limpar
                            </Button>

                            <Button
                                color="success"
                                type="button"
                                onClick={() => handleOk()}
                                style={{ width: '100px' }}
                            >OK
                            </Button>
                        </Col>
                    </Col>
                </Row>
            </ModalBasicFooter>
          
        </ModalBasic>
      
    )
}

export default ModalEndrecos

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { alertar } from "~/Util/alertar";
//import * as controllerConfiguracao from "~/controller/Cadastros/controllerConfiguracao"
import * as controllerMeusDados from "~/controller/Cadastros/controllerMeusDados"
//import { Switch } from "~/components/ErnComponets/ErnComponets";
import classNames from "classnames";
import ArrowTooltip from "~/components/Tooltip/ArrowTooltip";
import {maskPercentual, maskMoeda, maskNumbers} from "~/Util/mascaras";
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {
  Card,
  Col,
  Button,
  ButtonGroup,
  CardHeader,
  CardBody,
  CardTitle,
  Input,
  Row,
  TabContent,
  TabPane,
  FormGroup,
  CardFooter,
  Modal,
} from "reactstrap";

// core components
import ImageUpload from "~/components/CustomUpload/ImageUpload"

const idStore = "MEUSDADOS"

const MeusDadosCadastro = () => {
  const notificacao = useSelector((state) => state.notificacao);
  const login = useSelector((state) => state.login);

  //const { cadastroConfiguracao, cadastroConfiguracaoCopia } = useSelector((state) => state.Configuracao);
  const { cadastroMeusDados, cadastroMeusDadosCopia, invalido } = useSelector((state) => state.MeusDados);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [horizontalTabs, setHorizontalTabs] = useState("pesquisar");
  const [setWidht] = useState(window.innerWidth);

  async function Salvar(e) {
    e.preventDefault();
    if (cadastroMeusDados.idConfiguracao === '') {
      controllerMeusDados.CreateUpdateMeusDados(notificacao, dispatch, cadastroMeusDados, 'I', cadastroMeusDadosCopia);
    } else {
      controllerMeusDados.CreateUpdateMeusDados(notificacao, dispatch, cadastroMeusDados, 'A', cadastroMeusDadosCopia);
    }
  };

  async function handleBuscarCadastro() {
    setLoading(true);
    if (!loading) {
        await controllerMeusDados.GetMeusDados(notificacao, dispatch, login.usuario.empresa, () => {
            setInicioCadastro(true);
            dispatch({ idStore, type: 'CLEAN_INVALIDO' });

        });
        setLoading(false);
    }
  }

  function setInicioCadastro(valor) {
    dispatch({ idStore, type: "INICIO_CADASTRO", value: valor });
  }

  function handleOnChange(value, campo) {
    dispatch({ idStore, type: "MEUSDADOS", campo, value });
  }

  function handleBuscarCep(){
    controllerMeusDados.GetCep(notificacao, dispatch, cadastroMeusDados.cep);
    document.getElementById('numero').focus()
  }

  function handleKeyPress(event, type) {
    if (event.key === "Enter") {
      switch (type) {
        case "cep":
          handleBuscarCep();
          break;
        default:
      }
    }
  }



  function updateWidth() {
    setWidht(window.innerWidth);
  }


  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    handleBuscarCadastro();
  }, []);

  return (
    <>
      <div className="content">
        <Col className="mr-auto ml-auto" md="12">
          <Card>
             <CardHeader className="text-center">
              <CardTitle tag="h4">
               Meus Dados
              </CardTitle>
              <TabContent
                className="tab-space"
                activeTab={horizontalTabs}
                style={{ marginTop: "-30px", marginBottom: "-70px" }}
              >
                 <TabPane tabId="pesquisar">
                  {/* <h5>Configurações do Sistema</h5> */}
                </TabPane> 

              </TabContent>
            </CardHeader>

            <CardBody>
              <ButtonGroup style={{ width: "100%" }}>
                <ArrowTooltip title="">
                  <Button
                    color={horizontalTabs === "pesquisar" ? "info" : ""}
                    type="button"
                    data-toggle="tab"
                    className={classNames(
                      "btn-gp-padrao",
                      horizontalTabs === "pesquisar" ? "active" : ""
                    )}
/*                     onClick={() => {
                      changeActiveTab("pesquisar");
                    }}  */
                    style={{ width: "100%" }}
                  >
                    Cadastro
                  </Button>
                </ArrowTooltip>
              </ButtonGroup>
            </CardBody>
            <CardBody>

            </CardBody>
            <TabContent
              className="tab-space"
              activeTab={horizontalTabs}
              style={{ paddingBottom: "0px" }}
            >
              <TabPane tabId="pesquisar">
              <Row className="justify-content-center">  
                <Col md="10">
                  <Row>
                    <Col className="padding-padrao-col" lg="2" md="1">
                      <label>Id Empresa</label>
                        <Input
                          id="idEmpresa"
                          type="text"
                          disabled
                          value={cadastroMeusDados.idEmpresa}
                          //onChange={e => onChange(e.target.value, 'idUsuario')}
                          onChange={(e) =>
                            handleOnChange(e.target.value, e.target.id)
                          }
                          autoComplete="off"
                        />
                        <br></br>
                    </Col>
                    <Col className="padding-padrao-col" lg="2" md="1">
                      <label>Contrato</label>
                        <Input
                          id="numeroContrato"
                          type="text"
                          disabled
                          value={cadastroMeusDados.numeroContrato}
                          onChange={(e) =>
                            handleOnChange(e.target.value, e.target.id)
                          }
                          autoComplete="off"
                          maxLength="10"
                        />
                        <br></br>
                    </Col>
                    <Col className="padding-padrao-col" lg="3" md="1">
                      <label>CNPJ/CPF</label>
                      <FormGroup className={invalido.cnpjCpf ? "has-danger" : ''}>
                        <Input
                          id="cnpjCpf"
                          type="text"
                          value={cadastroMeusDados.cnpjCpf}
                          onChange={(e) =>
                            handleOnChange(maskNumbers(e.target.value), e.target.id)
                          }
                          autoComplete="off"
                          maxlength='15'
                        />
                        </FormGroup>
                        <br></br>
                    </Col>
                    <Col className="padding-padrao-col" lg="5" md="1">
                      <label>Nome Empresa</label>
                      <FormGroup className={invalido.nomeEmpresa ? "has-danger" : ''}>
                        <Input
                          id="nomeEmpresa"
                          type="text"
                          value={cadastroMeusDados.nomeEmpresa}
                          onChange={(e) =>
                            handleOnChange(e.target.value, e.target.id)
                          }
                          autoComplete="off"
                          maxlength='80'
                        />
                        </FormGroup>
                        <br></br>
                    </Col>
                  </Row>
                  
                  <Row className="justify-content-center" style={{ marginTop: '-25px' }}>
                    <Col className="padding-padrao-col" lg="2" md="2">
                      <label>CEP</label>
                      <FormGroup className={invalido.cep ? "has-danger" : ''}>
                        <Input
                          id="cep"
                          type="text"
                          value={cadastroMeusDados.cep}
                          onKeyPress={(e) => handleKeyPress(e, "cep")}
                          onChange={(e) =>
                            handleOnChange(e.target.value, e.target.id)
                          }
                          autoComplete="off"
                          maxlength='10'
                        />
                        </FormGroup>
                        <br></br>
                    </Col>     
                    <Col lg="auto">
                      <Button
                          style={{marginLeft:'-12px', marginTop: '27px', width: '40px', marginBottom: '0px' }}
                          color="info"
                          title='Buscar Endereço'
                          onClick={() => handleBuscarCep()}
                      >
                          <Icon icon="search" className="fa-lg" />
                      </Button>
                    </Col>                  
                    <Col style={{ width: '40px' }}>
                        <br></br>
                    </Col> 

                    <Col className="padding-padrao-col" lg="9" md="2">
                      <label>Endereço</label>
                      <FormGroup className={invalido.endereco ? "has-danger" : ''}>
                        <Input
                          id="endereco"
                          type="text"
                          value={cadastroMeusDados.endereco}
                          onChange={(e) =>
                            handleOnChange(e.target.value, e.target.id)
                          }
                          autoComplete="off"
                          maxlength='100'
                        />
                        </FormGroup>
                        <br></br>
                    </Col> 
                  </Row>      

                  <Row className="justify-content-center" style={{ marginTop: '-25px' }}>
                    <Col className="padding-padrao-col" lg="5" md="5">
                      <label>Bairro</label>
                      <FormGroup className={invalido.bairro ? "has-danger" : ''}>
                        <Input
                          id="bairro"
                          type="text"
                          value={cadastroMeusDados.bairro}
                          onChange={(e) =>
                            handleOnChange(e.target.value, e.target.id)
                          }
                          autoComplete="off"
                          maxlength='100'
                        />
                        </FormGroup>
                        <br></br>
                    </Col>     
                    <Col className="padding-padrao-col" lg="5" md="4">
                      <label>Cidade</label>
                      <FormGroup className={invalido.cidade ? "has-danger" : ''}>
                        <Input
                          id="cidade"
                          type="text"
                          value={cadastroMeusDados.cidade}
                          onChange={(e) =>
                            handleOnChange(e.target.value, e.target.id)
                          }
                          autoComplete="off"
                          maxlength='100'
                        />
                        </FormGroup>
                        <br></br>
                    </Col> 
                    <Col className="padding-padrao-col" lg="1" md="1">
                      <label>Estado</label>
                      <FormGroup className={invalido.uf ? "has-danger" : ''}>
                        <Input
                          id="uf"
                          type="text"
                          value={cadastroMeusDados.uf}
                          onChange={(e) =>
                            handleOnChange(e.target.value, e.target.id)
                          }
                          autoComplete="off"
                          maxlength='2'
                        />
                        </FormGroup>
                        <br></br>
                    </Col> 
                    <Col className="padding-padrao-col" lg="1" md="1">
                      <label>Número</label>
                      <FormGroup className={invalido.numero ? "has-danger" : ''}>
                        <Input
                          id="numero"
                          type="text"
                          value={cadastroMeusDados.numero}
                          onChange={(e) =>
                            handleOnChange(e.target.value, e.target.id)
                          }
                          autoComplete="off"
                          maxlength='10'
                        />
                        </FormGroup>
                        <br></br>
                    </Col>
                  </Row>  
                  <Row style={{ marginTop: '-25px' }}>
                  <Col className="padding-padrao-col" lg="5" md="2">
                      <label>Complemento</label>
                        <Input
                          id="complemento"
                          type="text"
                          value={cadastroMeusDados.complemento}
                          onChange={(e) =>
                            handleOnChange(e.target.value, e.target.id)
                          }
                          autoComplete="off"
                          maxlength='40'
                        />
                        <br></br>
                    </Col>     
                    <Col className="padding-padrao-col" lg="3" md="2">
                      <label>E-mail</label>
                        <Input
                          id="email"
                          type="text"
                          value={cadastroMeusDados.email}
                          onChange={(e) =>
                            handleOnChange(e.target.value, e.target.id)
                          }
                          autoComplete="off"
                          maxlength='45'
                        />
                        <br></br>
                    </Col>     
                    <Col className="padding-padrao-col" lg="2" md="2">
                      <label>Celular</label>
                        <Input
                          id="celular"
                          type="text"
                          value={cadastroMeusDados.celular}
                          onChange={(e) =>
                            handleOnChange(e.target.value, e.target.id)
                          }
                          autoComplete="off"
                          maxlength='11'
                        />
                        <br></br>
                    </Col>     

                  </Row>
                </Col>
              </Row>
              </TabPane>
              <CardFooter className="text-center">
                <Row className="justify-content-center">
                  <Col md="10" >
                    <Col className="text-center">
                    {/* <ArrowTooltip title='shift+L'><Button className="btn-padrao" color="default" onClick={(e) => Limpar(e)}>Limpar</Button></ArrowTooltip> */}
                    <ArrowTooltip title='Salvar'><Button className="btn-padrao" color="info" onClick={(e) => Salvar(e)}>Salvar</Button></ArrowTooltip>
                    </Col>
                  </Col>
                </Row>
              </CardFooter>                  
            </TabContent> 
          </Card>
          
        </Col>
      </div>

      {/* Modal Excluir */}

{/*       <Modal isOpen={modalExcluir} toggle={toggleModalExcluir}>
        <div className="modal-header justify-content-center">
          <button
            aria-hidden={true}
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModalExcluir}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
          <h6 className="title title-up">Excluir ICMS</h6>
        </div>

        <CardBody>
          <Row className="justify-content-center">
            <Col className="text-center">
              <Button
                color="danger"
                type="button"
                onClick={() => {
                  toggleModalExcluir();
                }}
                style={{ width: "150px" }}
              >
                NÃO
              </Button>
            </Col>
            <Col className="text-center">
              <Button
                color="success"
                type="button"
                onClick={() => {
                  toggleModalExcluir();
                  excluir();
                }}
                style={{ width: "150px" }}
              >
                SIM
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Modal>
 */}
      {/* Modal Excluir*/}
    </>
  );
};

export default MeusDadosCadastro;
